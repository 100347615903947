import { useEffect, useState } from "react";
import { apiFetch, FetchTypes } from "../../api/core";
import { useLoadedData } from "../../hooks/useLoadedData";
import { FieldType, Schema } from "../../hooks/useSchema";
import { useTextFilter } from "../schemed/Filtering/useTextFilter";

export interface Tag {
    _id: number;
    label: string;
    color?: string;
    count?: number;
}

export interface TagRecord {
    entity: string;
    record_id: string;
    name?: string;
}

export interface TagManagement {
    tags: Tag[];
    isLoading: boolean;
    reload: () => void;
    schema: Schema;

    filter: string;
    setFilter: (v: string) => void;

    create: (t: Partial<Tag>) => Promise<Tag>;
    remove: (t: Tag) => Promise<Tag>;
    update: (original: Tag, t: Partial<Tag>) => Promise<Tag>;
    duplicate: (original: Tag, t: Partial<Tag>) => Promise<Tag>;
    merge: (target: Tag, other: Tag) => Promise<Tag>;

    tagUsage: TagUsage;
}

export const useTagManagement = (apiPath: string): TagManagement => {
    const { data: tags, isLoading, reload } = useLoadedData<Tag[]>(apiPath, []);
    const tagUsage = useTagUsage(apiPath);

    const filter = useTextFilter<Tag>(t => t.label);

    const schema = {
        _id: { type: FieldType.number, label_id: "tags.labels.id"},
        label: { type: FieldType.text, label_id: "tags.labels.label"},
        color: { type: FieldType.text, label_id: "tags.labels.color"},
        count: { type: FieldType.number, label_id: "tags.labels.count"},
    }

    const create = (tag: Partial<Tag>) => {
        return apiFetch<Tag>(apiPath, FetchTypes.POST, tag)
            .then(t => { reload(); return t; });
    }

    const remove = (t: Tag) => {
        return apiFetch<Tag>(`${apiPath}/${t._id}?force=force`, FetchTypes.DELETE)
            .then(t => { reload(); return t; });
    }

    const update = (original: Tag, changes: Partial<Tag>) => {
        return apiFetch<Tag>(`${apiPath}/${original._id}`, FetchTypes.PUT, changes)
            .then(t => { reload(); return t; });
    }

    const duplicate = (original: Tag, dupe: Partial<Tag>) => {
        return apiFetch<Tag>(`${apiPath}/${original._id}/duplicate`, FetchTypes.POST, dupe)
            .then(t => { reload(); return t; });
    }

    const merge = (target: Tag, other: Tag) => {
        return apiFetch<Tag>(`${apiPath}/${target._id}/merge/${other._id}`, FetchTypes.POST)
            .then(t => { reload(); return t; });
    }

    return {
        tags: filter.filterData(tags),
        isLoading,
        reload,
        filter: filter.filter,
        setFilter: filter.setFilter,

        schema,

        create,
        update,
        remove,
        duplicate,
        merge,

        tagUsage,
    }
}

export interface TagUsage {
    tag: Tag | null;
    records: TagRecord[];
    isLoading: boolean;

    isActive: boolean;
    openFor: (tag: Tag) => void;
    close: () => void;
}

export const useTagUsage = (apiPath: string): TagUsage => {
    const [tag, setTag] = useState<Tag | null>(null);
    const data = useLoadedData<TagRecord[]>(`${apiPath}/${tag?._id || ""}/record`, [], !!tag);

    useEffect(() => {
        if(!tag) {
            data.setData([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag])


    return {
        tag,
        records: data.data,
        isLoading: data.isLoading,

        close: () => setTag(null),
        openFor: t => setTag(t),
        isActive: !!tag,
    }
}
