import { useMemo } from "react"
import { useEditItem2 } from "../../../api/useNewItem";
import moment from "moment";
import { DateInternalFormat, DateTimeInternalFormat, TimeInternalFormat, today } from "../../../api/datetimeUtil";

export const useTimeslotsGrouped = (slots: string[] | undefined) => {
  const [dates, slotsByDate] = useMemo(() => {
    const asDateTimes = (slots || []).map(v => moment(v));
    const slotsByDate = asDateTimes.reduce<Record<string,string[]>>(
      (r,d) => {
        const dateS = d.format(DateInternalFormat);
        const timeS = d.format(DateTimeInternalFormat);
        r[dateS] = r[dateS] || [];
        r[dateS].push(timeS);
        r[dateS].sort();

        return r;
      },
      {});

      const dateKeys = Object.keys(slotsByDate);
      dateKeys.sort();

      return [dateKeys, slotsByDate];
  }, [slots]);
  
  return {
    dates,
    slotsByDate,
    slots: slots || [],
  }
}


export const useTimeslotsConfigurationEdit = (value: string[] | undefined, update: (v: string[]) => void) => {
  const { dates, slotsByDate } = useTimeslotsGrouped(value);

  const addSlot = useEditItem2<{ date?: string, time?: string }>({
    save: (item) => {
      const date = item.date && moment(item.date);
      const time = item.time && moment(item.time);
      if(time) {
        time.set({ second: 0 });
      }
      if(!date || !time || !date.isValid() || !time.isValid()) {
        return Promise.reject({ response: { data: { errors: {
          date: !date || !date.isValid() ? ["missing"] : undefined,
          time: !time || !time.isValid() ? ["missing"] : undefined,
        }}}});
      }

      const slot = `${date.format(DateInternalFormat)} ${time.format(TimeInternalFormat)}`;
      const current = value || [];
      if(!current.includes(slot)) {
        const result = [ ...current, slot];
        result.sort();
        update(result);
      }

      return Promise.resolve(item);
    }
  });

  const removeSlot = (slot: string) => {
    update((value || []).filter(s => s !== slot));
  }

  return {
    value,
    dates,
    slotsByDate,
    update,
    addSlot,
    addSlotNewDate: () => addSlot.startEditing({
      date: dates.length ? dates.slice(-1)[0] : today(),
    }),
    addSlotToDate: (date: string) => addSlot.startEditing({ date }),
    removeSlot,
  }
}
