import React from 'react';
import { Button, Typography } from '@mui/material';
import { apiFetch } from '../../toolympus/api/core';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';
import { SnapshotsContainer } from '../../toolympus/components/Snapshots/SnapshotsContainer';

export const SnapshotsManagement = () => {
    const cleanup = () => apiFetch("/api/snapshot/clean", "post");

    const cleanupAction = useActionWithConfirmation(cleanup, {
        confirmationText: "clean",
        confirmationHint: <>
            <Typography key="l1">The database will be wiped out for the new iteration of the moot.</Typography>
            <Typography key="l2">This action is irreversible. To proceed, please type 'clean' into the textbox below and press the button.</Typography>
            </>,
        title: "Clean the database",
        confirmationLabel: "I acknowledge the consequences, clean it",

    });

    return <SnapshotsContainer
        apiPath="/api/snapshot"
        extraActions={<Button color="secondary" onClick={cleanupAction.run} disabled={!cleanupAction.canRun}>clean the database</Button>}
        />;
}
