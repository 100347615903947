import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom';
import { ScoreRoundForm } from '../../toolympus/components/Contests/Grid/Parties/ScoreRoundForm';
import { useScoreRoundAuthed } from '../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode';
import { useQuery } from '../../toolympus/hooks/useQueryParamsState';
import { Typography } from '@mui/material';
import { LoadingIndicator } from '../../toolympus/components/primitives';
import { theme } from '../../theme';
import { SpeakerSelect } from './SpeakerSelect';

const Wrapper = styled.div<{ isBlocked?: boolean }>`
    & .round-info {
        text-align: center;
    }

    & .done {
      text-align: center;
    }

    & button {
      pointer-events: ${props => props.isBlocked ? "none" : "unset"};
    }
`;

export const AdminAccessLSKey = "__vis_adm_access";

export const RoundScoringForm = () => {
    const { arbiter_id, round_id } = useParams<{ arbiter_id: string, round_id: string }>();
    const query = useQuery();
    const user_code = query.get("code") || query.get("user_code") || "";
    const data = useScoreRoundAuthed(`/api/rounds`, { round_id }, { queryParams: { user_id: arbiter_id, user_code }});
    const [isAdmin, setIsAdmin] = useState<boolean>(false); 

    const isAllScored = !!data.round.data.scores?.length && data.round.data.scores.every(s => s.is_scored);

    useEffect(() => {
      // this ls key will be set when visiting arbitrators list in case there is at least one person in the list
      // the list will be available and, even more so, will load only in case the user is an org
      const isAdmin = localStorage.getItem(AdminAccessLSKey) === "t";
      if(isAdmin) {
        setIsAdmin(true);
      }
      if(isAllScored && arbiter_id && user_code && !isAdmin) {
        const timeout = setTimeout(() => {
          window.location.href = `https://vismoot.ru/arbitrator-rounds?user_id=${arbiter_id}&user_code=${user_code}`;
        }, 10000);
        return () => clearTimeout(timeout);
      }
    }, [isAllScored, arbiter_id, user_code]);

    return (
        <Wrapper isBlocked={isAllScored && !isAdmin}>
            <ScoreRoundForm data={data} customComponents={{ speaker: SpeakerSelect }} />
            {isAllScored && !isAdmin &&
              <Typography className="done">
                Everything is scored.
                <br/>
                We will now redirect you back to the rounds.
                <br/>
                <br/>
                <span style={{ color: theme.palette.primary.main, fontWeight: 600 }}>Thank you!</span>
                <br />
                <br />
                <LoadingIndicator />
              </Typography>}
        </Wrapper>);
}
