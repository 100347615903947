import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#BD4139',
        },
        secondary: {
            main: '#336b87',
        },
    },
    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
