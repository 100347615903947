import { apiFetch, downloadFile } from "../../toolympus/api/core";
import { useTableComments } from "../../toolympus/components/Comments";
import { RegistrationsData } from "../../toolympus/components/Contests/Registrations";
import { useRegistrationsTabs } from "../../toolympus/components/Contests/Registrations/useRegistrationsTabs";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useTags } from "../../toolympus/components/Tags";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { ContestSettings, useContestSettings } from "../Configuration/ControlPage";
import { ArbiterReview, useArbiterReview } from "./useArbiterReview";


export interface Arbiter {
    _id: string;
    fullname: string;
    country: string;
    access_code: string;
    affiliation_universities: string;
    affiliation_teams: string;
    email: string;
    timeslots_preferrence?: Record<string, Record<string, boolean> | null>;
    timeslots_preferrence_new?: string[];
    exclude?: boolean;
}

export interface ArbiterListData extends RegistrationsData<Arbiter> {
    review: ArbiterReview;
    settings: ContestSettings;
}

export const ArbiterApiPath = '/api/admin/arbiter';

export const useArbiterList = (): ArbiterListData => {
    const data = useLoadedData<Arbiter[]>(ArbiterApiPath, []);
    const { schema } = useSingleSchema(`${ArbiterApiPath}/uiconfig`);
    const settings = useContestSettings();

    const approval = {
        isRegistrationApproved: (a: Arbiter) => !a.exclude,
        update: (a:Arbiter, approve: boolean, comment?: string) => {
            return apiFetch<Arbiter>(`${ArbiterApiPath}/${a._id}`, "put", { exclude: !approve })
                .then(t => {
                    data.reload();
                    return t;
                })
        }
    };

    const remove = (a: Arbiter) => apiFetch(`${ArbiterApiPath}/${a._id}`, "delete").then(() => data.reload());

    const tabs = useRegistrationsTabs({ approval, queryParam: "tab" });

    const filter = useTextFilter<Arbiter>(t => `${t.email} ${t.fullname} ${t.country} ${t.affiliation_universities} ${t.affiliation_teams}`, "", { exactFields: ["_id"] });
    const tags = useTags("/api/conflict/tag", "arbiter");
    const comments = useTableComments("/api/comment", "arbiter", { isRemoveAllowed: () => false });

    const exportData = () => downloadFile(`${ArbiterApiPath}/export`, "vis_arbitrators.xlsx");

    const review = useArbiterReview(() => data.reload());

    return {
        ...data,
        data: filter.filterData(data.data.filter(t => tabs.activeFilter(t))),
        remove,
        exportData,
        approval,
        tabs,

        ...filter,
        schema,
        tags,
        comments,
        review,
        settings,
    }
}
