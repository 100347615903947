import React from 'react';
import { EditItemProps } from '../../../api/useNewItem';
import { Dialog, FormGrid, LoadingIndicator } from '../../primitives';
import { FormattedMessage } from 'react-intl';
import { Button, TextField, Typography } from '@mui/material';

interface Props {
  data: EditItemProps<{ pairsText: string }>;
}

export const PlayersAssignByOrderImportDialog = (props: Props) => {
  const { data } = props;
  return (
    <Dialog
      dialogTitle={<FormattedMessage id="contests.rounds.config.stages.assign_players_by_order" />}
      noSubmitOnEnter
      isOpen={data.isEditing}
      close={() => data.cancel()}
      titleActions={<>{data.isLoading && <LoadingIndicator />}</>}
      actions={<Button color="primary" variant="contained" onClick={() => data.save()}><FormattedMessage id="common.submit" /></Button>}
      maxWidth="md"
      fullWidth>
      <FormGrid columns="1fr" noMargin>
        <Typography style={{ whiteSpace: "pre-line"}}>
          <FormattedMessage id="contests.rounds.config.stages.assign_players_by_order_details" />
        </Typography>

        {data.item &&
          <TextField
            value={data.item.pairsText}
            onChange={e => data.update({ pairsText: e.target.value })}
            multiline
            />}
      </FormGrid>
    </Dialog>
  );
}
