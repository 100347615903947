import React, { ReactNode, useContext, useMemo } from 'react';
import { Tag } from './useTagManagement';
import { useAllTags } from './useTags';

export interface IAvailableTags {
    tags: Tag[];
    tagsDict: Record<number, Tag>;
    isLoading: boolean;
    reload: () => void;
    fromKnownIDs: (ids?: number[]) => Tag[];
}

const AvailableTagsContext = React.createContext<IAvailableTags>({
    tags: [],
    tagsDict: {},
    isLoading: false,
    reload: () => { },
    fromKnownIDs: () => [],
});

interface Props {
    apiPath?: string;
    children?: ReactNode;
}

export const useAvailableTagsBare = (apiPath?: string): IAvailableTags => {
  const { data: tags, isLoading, reload } = useAllTags(apiPath || "", !apiPath);
  const [tagsDict, fromKnownIDs] = useMemo(() => {
    const tagsDict = tags.reduce((r,t) => ({ ...r, [t._id]: t }), {}) as Record<number, Tag>;
    const fromKnownIDs: IAvailableTags["fromKnownIDs"] = ids => (ids || []).map(t => tagsDict[t]).filter(t => !!t);
    return [
      tagsDict,
      fromKnownIDs,
    ]
  }, [tags]);

  return {
    tags,
    tagsDict,
    fromKnownIDs,
    isLoading,
    reload,
  }
}

export const AvailableTagsProvider = (props: Props) => {
    const ctx = useAvailableTagsBare(props.apiPath);

    return (
        <AvailableTagsContext.Provider value={ctx}>
            {props.children}
        </AvailableTagsContext.Provider>
    );
}

export const useAvailableTags = () => useContext(AvailableTagsContext);
