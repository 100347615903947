import React from 'react';
import { CustomElement } from '../../../../slate';
import { extractCustomBlockFields } from '../../PowerDoc/plugins/CustomFieldsBlock';

export const processBasicExtraLinks = (linksText?: string): [string,string][] => {
  const items = (linksText || "").split("\n").map(l => l.trim()).filter(l => !!l);
  return items.map(line => line.split(";").map(p => p.trim()) as [string,string]);
}

export const processExtraLinks = (linksText?: string) => {
  return processBasicExtraLinks(linksText).map(([text,link]) => {
    const linkInside = text.match(/\[[^\]]+\]/);
    if(!linkInside) {
      return <span><a href={link}>{text}</a></span>;
    } else {
      const [linkText] = linkInside;
      const before = text.substring(0, linkInside.index);
      const after = text.substring((linkInside.index || 0) + linkText.length);
      const linkInner = linkText.substring(1,linkText.length-1);
      return <span>{before}<a href={link}>{linkInner}</a>{after}</span>
    }
  })
}

export const getCustomBlocksData = (blocks: CustomElement[] | undefined): Record<string, Record<string, string>> => {
  return (blocks || []).reduce<Record<string, Record<string, string>>>((result,b: CustomElement) => {
    if(b.type === "custom_fields_block") {
      const fields = extractCustomBlockFields(b);
      if(fields.block_type) {
        result[fields.block_type] = fields;
      }
    }
    return result;
  }, {});
}
