import React from 'react';
import styled from '@emotion/styled'
import LogoImg from '../../logo.svg';
import LogoImg2 from '../../logo_w.svg';

export const LogoW = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 1.5rem;
`;
LogoW.defaultProps = { alt: "vismoot.ru" };

interface Props {
    version?: 1 | 2;
}

export const Logo = ({ version }: Props) => <LogoW src={version === 2 ? LogoImg2 : LogoImg} />;
