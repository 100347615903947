import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClipboardButton } from '../../primitives/Buttons';
import { FormGrid } from '../../primitives/Forms';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';
import { ProblemsContainer, ProblemMessages, TimezoneSetting } from './Common';
import { useGridSettings } from './GridSettingsContext';
import { genArbiterPositions } from './GridStageForm';
import { EditRoundData } from './useEditRound';

interface Props {
    data: EditRoundData;
    roundsTimeZone?: TimezoneSetting;
}

export const EditRoundPopup = (props: Props) => {
    const { isEditing, isLoading, save, cancel, item, controls, meetingControls, selectPlayer, selectArbiter } = props.data;
    const { formatMessage } = useIntl();
    const gridSettings = useGridSettings();
    const { arbiter_links_enabled: links_enabled, judges_per_stage_round, conference_kind } = gridSettings;

    const RoundExtra = gridSettings.admin_round_popup_round_extra_component;
    const PlayerDetails = gridSettings.admin_round_popup_player_info_component;

    return (
        <Dialog open={isEditing} onClose={cancel}>
            <DialogTitle>
                <FormattedMessage id="contests.rounds.round.edit" values={item as Record<string, any> || {}} />
                {isLoading && <LoadingIndicator />}
            </DialogTitle>

            {item && <DialogContent>
                {item?.problems && Object.keys(item?.problems).length &&
                    <ProblemsContainer><ProblemMessages problems={item.problems} /></ProblemsContainer>}

                <FormGrid columns="3fr 2fr" forceEvenColumns
                    areas={["title time", "description description", "join_url join_url"]}>
                    {controls([
                        ["title", { withArea: true }],
                        ["time", { withArea: true, utcToLocal: props.roundsTimeZone !== "utc" }],
                        ["description", { withArea: true, autoRows: true }],
                    ])}
                    {conference_kind === "zoom" && meetingControls([
                        ["join_url", { withArea: true }],
                    ])}
                </FormGrid>

                {RoundExtra && <RoundExtra round={item} />}
                
                <FormGrid columns="1fr 1fr" forceEvenColumns>
                    <TextField
                        key="player1"
                        label={formatMessage({ id: "contests.rounds.round.labels.player1" })}
                        value={item?.players[1]?.display_name || formatMessage({ id: "contests.rounds.round.labels.player_unassigned" })}
                        onClick={() => selectPlayer.open({ round: item, position: 1 })}
                        InputProps={{
                            endAdornment: item?.players[1] ?
                                <IconButton size="small" onClick={e => {
                                    e.stopPropagation();
                                    selectPlayer.clearPlayer({ round: item, position: 1 });
                                    }}>
                                    <Close />
                                </IconButton>
                                : undefined,
                        }}
                        />
                    <TextField
                        key="player2"
                        label={formatMessage({ id: "contests.rounds.round.labels.player2" })}
                        value={item?.players[2]?.display_name || formatMessage({ id: "contests.rounds.round.labels.player_unassigned" })}
                        onClick={() => selectPlayer.open({ round: item, position: 2 })}
                        InputProps={{
                            endAdornment: item?.players[2] ?
                            <IconButton size="small" onClick={e => {
                                e.stopPropagation();
                                selectPlayer.clearPlayer({ round: item, position: 2 });
                                }}>
                                <Close />
                            </IconButton>
                            : undefined,
                        }}
                        />

                    {PlayerDetails && <PlayerDetails round={item} position={1} />}
                    {PlayerDetails && <PlayerDetails round={item} position={2} />}
                </FormGrid>

                <FormGrid columns={links_enabled ? "1fr max-content" : "1fr"} gap="dense" style={{ alignItems: 'flex-end' }}>
                    {genArbiterPositions((judges_per_stage_round || {})[item.stage_code]).map(position => {
                        const arb = item?.arbiters[position];
                        return <React.Fragment key={position}>
                            <TextField
                                key={position}
                                label={formatMessage({ id: "contests.rounds.round.labels.arbiter" }, { position })}
                                value={arb?.display_name || formatMessage({ id: "contests.rounds.round.labels.arbiter_unassigned" })}
                                onClick={() => selectArbiter.open({ round: item, position })}
                                InputProps={{
                                    endAdornment: arb ?
                                    <IconButton size="small" onClick={e => {
                                        e.stopPropagation();
                                        selectArbiter.clearArbiter({ round: item, position });
                                        }}>
                                        <Close />
                                    </IconButton>
                                    : undefined,
                                }}
                                />
                            {links_enabled && arb && arb.access_url && arb.access_url.length ? <ClipboardButton value={arb.access_url} /> : <div />}
                        </React.Fragment>
                    })}
                </FormGrid>
            </DialogContent>}

            <DialogActions>
                <Button onClick={cancel}><FormattedMessage id="common.cancel" /></Button>
                <Button color="primary" variant="contained" onClick={() => save()}><FormattedMessage id="common.save" /></Button>
            </DialogActions>
        </Dialog>
    );
}
