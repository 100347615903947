import React, { useContext } from 'react';
import { RoundScore, RoundToScore } from './useScoreRoundByCode';

interface Data {
    round: RoundToScore | null;
    score: RoundScore | null;
}

export const ScoredRoundContext = React.createContext<Data>({ round: null, score: null });

export const useScoredRound = () => useContext(ScoredRoundContext);