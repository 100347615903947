import React, { useMemo } from 'react';
import { GridConfigurationContainer, GridSettings, GridSettingsProvider } from '../../toolympus/components/Contests/Grid';
import { Chip } from '@mui/material';
import moment from 'moment';
import { DateSlotsWrapper } from '../../toolympus/components/Contests/Timeslots';
import { Arbiter } from '../../toolympus/components/Contests/Grid/types';


const SlotFormat = "D MMM HH:mm"

const TimeSlotsDisplay = (props: Arbiter) => {
  const slots = useMemo(() => {
    const slots = (props.info?.timeslots || []).sort().map((s: string) => moment(s).format(SlotFormat)) as string[];
    const occupiedSlots = (props.timeslots_assigned || []).map((s: string) => moment(s).format(SlotFormat)) as string[];
    return [
      ...slots.map(slot => ({ slot, isOccupied: occupiedSlots.includes(slot) })),
      ...occupiedSlots.filter(s => !slots.includes(s)).map(slot => ({ slot, isOccupied: true })),
    ]
  },[props.info, props.timeslots_assigned]);
  
  return (
    <DateSlotsWrapper>
      {slots.map(({ slot, isOccupied }) => (
        <Chip
          size="small"
          label={slot}
          style={isOccupied ? { textDecoration: "line-through" } : {}}
          disabled={isOccupied}
          />))}
    </DateSlotsWrapper>
  )
}

const Settings: Partial<GridSettings> = {
  arbiter_extra_columns: [
    ["Timeslots", TimeSlotsDisplay]
  ],
}

export const RoundsConfigurationPage = () => {
    return (
      <GridSettingsProvider
        settings={Settings}>
          <GridConfigurationContainer
            apiPath="/api/rounds"
            tagsApiPath="/api/conflict/tag"
            />
      </GridSettingsProvider>);
}
