import { useHistory } from "react-router-dom"
import { useSendData } from "../useSendData"

interface ResetPasswordBody {
    login: string;
}

export const useResetPassword = (resetAPIPath: string) => {
    const history = useHistory();

    const { data, update, send, isSaving, isSaved } = useSendData<ResetPasswordBody, string>(resetAPIPath, { login: '' }, {
        apiExtraParams: { noAuth: true },
        onSuccess: (redirectPath) => {
            history.push(redirectPath);
        }
    })

    const updateWrapper = (login: string) => update({ ...data, login: login });

    return {
        email: data.login,
        updateEmail: updateWrapper,
        restore: send,
        isSaving,
        isSaved,
    }
}

export type ResetPasswordData = ReturnType<typeof useResetPassword>;
