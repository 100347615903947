import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGridSettings } from '.';
import { apiFetch, FetchTypes } from '../../../api/core';
import { useLoadedData } from '../../../hooks/useLoadedData';
import { FieldType } from '../../../hooks/useSchema';
import { Dialog } from '../../primitives/Dialogs';
import { SearchField } from '../../primitives/SearchField';
import { TableForFields } from '../../schemed';
import { useTextFilter, WithTextFilter } from '../../schemed/Filtering/useTextFilter';
import { TagsDisplay, useAvailableTags } from '../../Tags';
import { Arbiter } from './types';
import { Round } from './types';
import { Button } from '@mui/material';

interface Props extends WithTextFilter {
    isOpen: boolean;
    close: () => void;
    arbiters: Arbiter[];
    saveSelected: (p?: Arbiter) => void;
    selected: Arbiter | null;
    setSelected: (p: Arbiter | null) => void;
}

const SelectArbiterPopup = (props: Props) => {
    const { isOpen, close, arbiters, filter, setFilter, selected, setSelected, saveSelected } = props;
    const tags = useAvailableTags();

    const settings = useGridSettings();
    const extraColumns = settings.arbiter_extra_columns || [];
    
    return (
        <Dialog
            isOpen={isOpen} close={close} maxWidth="md" fullWidth
            dialogTitle={<FormattedMessage id="contests.rounds.arbiter.select" />}
            actions={<>
              <Button onClick={() => close()}><FormattedMessage id="common.close" /></Button>
              <Button variant="contained" color="primary" disabled={!selected} onClick={() => saveSelected()}><FormattedMessage id="common.select" /></Button>
            </>}
            titleActions={<>
              <SearchField filter={filter} setFilter={setFilter} noButton autoFocus doSearch={() => {
                  if(selected) {
                      saveSelected();
                  } else if (arbiters.length === 1) {
                      saveSelected(arbiters[0]);
                  }
                }} />
            </>}
            >

            <TableForFields
                data={arbiters}
                fields={[
                    ["display_name"],
                    ["rounds_assigned"],
                    ["description"],
                    ["tags"],
                    ...extraColumns.map(([f]) => [f] as [string]),
                ]}
                schema={{
                    display_name: { type: FieldType.text, label_id: "contests.rounds.arbiter.labels.display_name"},
                    rounds_assigned: { type: FieldType.text, label_id: "contests.rounds.arbiter.labels.rounds_assigned"},
                    description: { type: FieldType.text, label_id: "contests.rounds.arbiter.labels.description"},
                    tags: { type: FieldType.text, label_id: "contests.rounds.player.labels.tags"},
                }}
                rowStyle={r => !!selected && selected._id === r._id ? { background: "#00cc0030"} : {}}
                fieldElement={f => {
                    if(f === "tags") {
                        return r => <TagsDisplay wrap tags={tags.fromKnownIDs(r.tags)} />
                    }

                    const extraField = extraColumns.find(([fe]) => fe === f);
                    if(extraField) {
                        return r => <>{extraField[1](r)}</>;
                    }
                }}
                onRowClick={setSelected}
                onDblClick={p => saveSelected(p)}
                />

        </Dialog>
    );
}

interface Target {
    round: Round;
    position: number;
    onSave?: (updateRound: Round) => void;
}

export interface SelectArbiter {
    popup: ReactNode;
    open: (target: Target, selected?: Arbiter) => void;
    clearArbiter: (target: Target) => void;
    arbitersInfo: Record<string, Arbiter>;
}

interface Config {
    onSave?: (updatedRound: Round) => void;
}

export const useSelectArbiter = (apiPath: string, cfg?: Config): SelectArbiter => {
    const [target, setTarget] = useState<Target | null>(null);
    const [selectedArbiter, setSelectedArbiter] = useState<Arbiter | null>(null);
    const data = useLoadedData<Arbiter[]>(
        `${apiPath}/arbiters`,
        [],
        !!target);
    const filter = useTextFilter<Arbiter>(p => `${p.display_name} ${p.description}`);

    const close = () => { setTarget(null); filter.setFilter(""); setSelectedArbiter(null); };

    const doArbitersUpdate = (target: Target, arbiter: Arbiter | null)  => {
        const arbiters = {
            ...target.round.arbiters,
            [target.position]: arbiter ? { _id: arbiter._id } : null,
        };
        return apiFetch<Round>(`${apiPath}/stage/${target?.round?.stage_code}/round/${target?.round?._id}`, FetchTypes.PUT, {
            arbiters,
        })
        .then(round => {
            data.reload();
            if(cfg?.onSave) {
                cfg.onSave(round);
            }
            if(target?.onSave) {
                target.onSave(round);
            }
            close();
            return round;
        });
    }

    const saveSelected = (p: Arbiter | undefined) => {
        const arbiter = p || selectedArbiter
        if(!!target && !!arbiter) {
            return doArbitersUpdate(target, arbiter);
        }
    }

    

    const clearArbiter = (target: Target) => {
        return doArbitersUpdate(target, null);
    }

    const popup = (
        <SelectArbiterPopup
            isOpen={!!target}
            close={close}
            filter={filter.filter}
            setFilter={filter.setFilter}
            arbiters={filter.filterData(data.data)}
            saveSelected={saveSelected}
            selected={selectedArbiter}
            setSelected={setSelectedArbiter}
            />)

    return {
        popup,
        open: (t,p) => {
            setTarget(t);
            if(p) {
                setSelectedArbiter(p);
            }
        },
        clearArbiter,
        arbitersInfo: data.data.reduce((r,p) => ({ ...r, [p._id]: p }), {}),
    };
}