import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Registration, RegistrationApproval } from ".";
import { TabsState, useTabsState } from "../../primitives/Tabs";

type TabConfigX<T>  = [string, ReactNode, (r: T) => boolean];

interface Config<T extends Registration> {
    extraTabs?: TabConfigX<T>[];
    defaultTab?: string;
    tabsOrder?: string[];
    approval?: RegistrationApproval<T>;
    queryParam?: string;
}

export interface RegistrationsTabs<T extends Registration> extends TabsState {
    activeFilter: (r: T) => boolean;
}

export const useRegistrationsTabs = <T extends Registration,>(config: Config<T>): RegistrationsTabs<T> => {
    const defaultTabs: TabConfigX<T>[] = [
        ["approved", <FormattedMessage id="contests.registrations.tabs.approved" />, (r: T) => !!config.approval?.isRegistrationApproved(r)],
        ["unapproved", <FormattedMessage id="contests.registrations.tabs.unapproved" />, (r: T) => !config.approval?.isRegistrationApproved(r)],
        ["all", <FormattedMessage id="contests.registrations.tabs.all" />, () => true],
    ];
    const defaultTabsKeyed = defaultTabs.reduce((r,t) => ({ ...r, [t[0] as string]: t }), {} as Record<string, TabConfigX<T>>);
    const extraKeyed = (config.extraTabs || []).reduce((r,t) => ({ ...r, [t[0]]: t }), {} as Record<string, TabConfigX<T>>);

    let allTabs: TabConfigX<T>[] = [];
    if(config.tabsOrder) {
        allTabs = config.tabsOrder.map(tkey => extraKeyed[tkey] || defaultTabsKeyed[tkey]).filter(t => !!t);
    } else if(config.approval) {
        allTabs = [...(config.extraTabs || []), ...defaultTabs];
    } else {
        allTabs = config.extraTabs || [];
    }

    const tabsState = useTabsState(allTabs.map(t => t.slice(0,2) as [string, ReactNode]), config.defaultTab, config.queryParam);

    return {
        ...tabsState,
        activeFilter: (extraKeyed[tabsState.current] || defaultTabsKeyed[tabsState.current] || ["", "", () => true])[2],
    }
}
