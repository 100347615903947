import React from 'react';
import { useSettings } from '../../toolympus/api/useSettings';
import { ActionRow, FormGrid, LoadingIndicator, OccupyFreeSpace, SaveButton, useSaveable } from '../../toolympus/components/primitives';
import { PlaceholderField } from '../../toolympus/components/schemed';


export const SiteSettingsForm = () => {
    const settings = useSettings("/api/site-settings", {});
    useSaveable(settings);
    
    return (
        <>
            <ActionRow>
                <OccupyFreeSpace />
                {settings.isLoading && <LoadingIndicator />}
                {settings.hasChanges && <SaveButton action={() => settings.save()} />}
            </ActionRow>
            <FormGrid columns="1fr 1fr" forceEvenColumns>
                {settings.withControls.controls([
                    ["site_title"],
                    ["site_description"],
                    ["site_image"],
                    [PlaceholderField],
                    ["title"],
                    ["subtitle"],

                    ["is_team_registration_button_visible"],
                    ["teamRegistrationURL"],
                    ["is_arbiter_registration_button_visible"],
                    ["arbiterRegistrationURL"],
                    ["is_conference_registration_button_visible"],
                    ["conferenceRegistrationURL"],                    
                ])} 
            </FormGrid>

            <FormGrid columns="1fr">
                {settings.withControls.controls([
                    ["menu"],
                ])} 
            </FormGrid>   
        </>
    );
}
