import { useCallback, useRef, useState } from "react"

export const useThrottledState = <T,>(dflt: T, throttleDelay: number = 50) => {
  const [state, setState] = useState<T>(dflt);
  const ref = useRef<T>(dflt);
  const timeout = useRef<any>(null);

  const setStateX = useCallback((newState: T) => {
    if(timeout.current) {
      clearTimeout(timeout.current);
    }
    ref.current = newState;
    timeout.current = setTimeout(() => { setState(ref.current) }, throttleDelay);
  }, [throttleDelay]);

  return [
    state,
    setStateX,
  ] as [T,(x:T) => void];
}
