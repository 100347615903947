import { OpenRegistrationReviewData, useOpenRegistrationReview } from "../../toolympus/components/Contests/Registrations";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { Team, TeamApiPath } from "./useTeamList";

export interface TeamReview extends OpenRegistrationReviewData<Team> {
    // approve: () => Promise<any>;
    // reject: (isForFixes: boolean, explanation: string) => Promise<any>;
}

export const useTeamReview = (onSaved: (t: Team) => void): TeamReview => {
    
    const { schema } = useSingleSchema(`${TeamApiPath}/uiconfig`);

    const data = useOpenRegistrationReview<Team>({
        apiPath: TeamApiPath,
        schema,
        onSaved,
        comments: {
            apiPath: "/api/comment",
            entity: "team",
            config: { isRemoveAllowed: () => false },
        }
    });

    return {
        ...data,
    }
}