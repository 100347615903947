import React from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { Add, Close, Warning } from '@mui/icons-material';
import { TeamMember } from './useTeamList';

interface Props {
  value: TeamMember[];
  update: (v: TeamMember[]) => void;
  hasError?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 0.25rem;
`;

export const TeammembersEdit = ({ value, update, hasError }: Props) => {
  const updateMember = (idx: number, changes: Partial<TeamMember>) => {
    update(value.map((m,i) => i === idx ? { ...m, ...changes }: m));
  }

  return (
    <Wrapper>
      <Typography variant="caption" color="textSecondary">
        Team members
        {hasError && <Warning color="error" style={{ marginLeft: "0.5rem" }} />}
      </Typography>
      <div />

      {value.map((m,i) => (
        <React.Fragment>
          <TextField
            value={m.fullname}
            onChange={e => updateMember(i, { fullname: e.target.value })}
            placeholder="Full name"
            fullWidth
            />
          <IconButton size="small" onClick={() => update(value.filter((_,idx) => idx !== i))}><Close /></IconButton>
        </React.Fragment>
      ))}
      <Button size="small" style={{ justifySelf: "start" }} onClick={() => update([...value, { fullname: "" }])} startIcon={<Add />}>add</Button>
    </Wrapper>
  );
}
