import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { List, ListItem, ListItemText, TextField } from '@mui/material';
import { EditorCommand } from '../PowerEditorConfig';
import { Editor } from 'slate';
import isHotkey from 'is-hotkey';
import { useTextFilter } from '../../../schemed/Filtering/useTextFilter';
import { FormattedMessage } from 'react-intl';
import { translateHotkey } from '../../../primitives';
import { StockCommands } from '../StockCommands';
import { ReactEditor } from 'slate-react';

interface Props {
    commands: EditorCommand[];
    editor: Editor;

    isOpen: boolean;
    close: () => void;
}

const Search = styled.div`
    display: flex;
    flex-flow: row;
    width: 100%;

    & > :first-child {
        flex: 1 0 auto;
    }
`;

const Wrapper = styled.div`
    position: fixed;
    top: 5vh;
    left: 20%;
    width: 60%;
    height: auto;
    padding: 0 1rem 0.5rem;
    box-shadow: 0 0 10px -5px #33333380;
    z-index: 2000;
    background: ${props => props.theme.palette.background.default};
`;

const useCommandPalette = (config: Pick<Props, "commands" | "editor">) => {
    const filter = useTextFilter<EditorCommand>(cmd => cmd.name.toLowerCase(), "");

    const allCommands = useMemo(
        () => [...StockCommands, ...config.commands].filter(c => !c.isInternal),
        [config.commands]);

    const filteredCommands = filter.filter.length ? filter.filterData(allCommands) : [];

    
    const [activeCommandIndex, setActiveCommandIndex] = useState<number>(0);
    const activeCommand = filteredCommands[activeCommandIndex];

    useEffect(() => {
        setActiveCommandIndex(0);
    }, [filter.filter]);

    const invokeCommand = (cmd?: EditorCommand) => {
        if(cmd) {
            cmd.invoke(config.editor);
        }
        ReactEditor.focus(config.editor);
    };

    return {
        search: filter.filter,
        setSearch: filter.setFilter,
        filteredCommands,
        activeCommand,
        activeCommandIndex,

        nextCommand: () => setActiveCommandIndex(x => x + 1 >= filteredCommands.length ? 0 : x + 1),
        prevCommand: () => setActiveCommandIndex(x => x - 1 < 0 ? Math.max(0, filteredCommands.length - 1) : x - 1),

        invokeCommand,
        invokeActiveCommand: () => invokeCommand(activeCommand),
    }
}

export const CommandPalette = (props: Props) => {
    const palette = useCommandPalette(props);

    useEffect(() => {
        if(!props.isOpen) {
            palette.setSearch("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    if(!props.isOpen) {
        return null;
    }


    return (
        <Wrapper>
            <Search>
                <TextField
                    autoFocus
                    value={palette.search}
                    onChange={e => palette.setSearch(e.target.value)}
                    onKeyDown={e => {
                        if(isHotkey("Enter", e)) {
                            palette.invokeActiveCommand();
                            props.close();
                            e.stopPropagation();
                            e.preventDefault();
                        }
                        else if(isHotkey("Esc", e)) {
                            props.close();
                            ReactEditor.focus(props.editor);
                            e.stopPropagation();
                            e.preventDefault();
                        }
                        else if(isHotkey("down", e)) {
                            palette.nextCommand();
                            e.stopPropagation();
                        }
                        else if(isHotkey("up", e)) {
                            palette.prevCommand()
                            e.stopPropagation();
                        }
                    }}
                    />
            </Search>
            {palette.filteredCommands.length > 0 && <List dense>
                {palette.filteredCommands.map((cmd,idx) => (
                    <ListItem
                        key={cmd.name}
                        button
                        selected={idx === palette.activeCommandIndex}
                        onClick={() => { palette.invokeCommand(cmd); props.close(); }}>
                        <ListItemText
                            primary={cmd.menu?.label_id
                                ? <FormattedMessage id={cmd.menu?.label_id} values={cmd.menu.label_values} />
                                : cmd.menu?.label || cmd.name}
                            secondary={`${cmd.menu?.label_id || cmd.menu?.label ? cmd.name : ""} ${cmd.hotkey ? `(${translateHotkey(cmd.hotkey)})` : ""}`}
                            />
                    </ListItem>))}
            </List>}
        </Wrapper>
    );
}
