import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { ArrowDownwardOutlined, CloseOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { CodedError } from '../app/ErrorBoundary';
import { TagDisplay } from './TagDisplay';
import { Tag } from './useTagManagement';

export interface TagMergerData {
    target: Tag | null;
    other: Tag | null;
    addToMerge: (t: Tag) => void;
    
    isReady: boolean;
    cancelTarget: () => void;
    cancel: () => void;

    execute: () => Promise<any>;
    isLoading: boolean;
}

export const useTagMerger = (executeMerge: (target: Tag, other: Tag) => Promise<any>): TagMergerData => {
    const [other, setOther] = useState<Tag | null>(null);
    const [target, setTarget] = useState<Tag | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isReady = !!other && !!target;

    const execute = () => {
        if(!isReady) {
            throw new CodedError("tags.error.cant_merge_no_tags");
        }

        setIsLoading(true);
        return executeMerge(target as Tag, other as Tag)
            .then(x => { setIsLoading(false); setOther(null); setTarget(null); return x; })
            .catch(e => { setIsLoading(false); throw e; });
    }

    const addToMerge = (tag: Tag) => {
        if(!other) {
            setOther(tag);
        } else if(tag._id !== other._id) {
            setTarget(tag);
        }
    }

    return {
        other,
        target,
        addToMerge,

        isReady,
        cancelTarget: () => setTarget(null),
        cancel: () => { setOther(null); setTarget(null); },
        
        isLoading,
        execute,
    }
}

export interface TagMergerStatusProps {
    positioning?: 'as-is' | 'fixed';
}

interface Props extends TagMergerStatusProps {
    data: TagMergerData;
}

const StatusWrapper = styled.div<TagMergerStatusProps>`
    display: flex;
    flex-flow: row;
    gap: 0.5rem;
    align-items: center;

    ${props => {
        if(props.positioning !== 'as-is') {
            return `
                position: fixed;
                bottom: 1rem;
                left: 50%;
                transform: translateX(-50%);
                z-index: 300;
                background: #fff;
                border-radius: 8px;
                box-shadow: 0 0 10px #00000040;
                padding: 0.5rem 1rem;
            `;
        }
    }}
`;

const PreviewWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
`;

export const TagMerger = (props: Props) => {
    const { other, target, cancelTarget, isReady, cancel, execute } = props.data;
    return <>
        {other &&
            <StatusWrapper positioning={props.positioning}>
                <Typography><FormattedMessage id="tags.merge.select_source" /></Typography>
                <TagDisplay tag={other} wrap />
                <IconButton onClick={() => cancel()} size="small"><CloseOutlined /></IconButton>
            </StatusWrapper>
        }
        <Dialog open={isReady} onClose={() => cancelTarget()} fullWidth maxWidth="sm">
            <DialogTitle><FormattedMessage id="tags.merge.confirmation" /></DialogTitle>
            <DialogContent>
                {other && target && (
                    <PreviewWrapper>
                        <TagDisplay tag={other} wrap />
                        <ArrowDownwardOutlined />
                        <TagDisplay tag={target} wrap />
                    </PreviewWrapper>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => cancelTarget()}><FormattedMessage id="common.cancel" /></Button>
                <Button color="primary" variant="contained" onClick={() => execute()}><FormattedMessage id="tags.merge.execute" /></Button>
            </DialogActions>
        </Dialog>
    </>;
}
