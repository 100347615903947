import React from 'react';
import { useScoredRound } from '../../toolympus/components/Contests/Grid/Parties/ScoredRoundContext';
import { ScoringControlProps } from '../../toolympus/components/Contests/ScoringSheets';
import { Select } from '../../toolympus/components/schemed/Select';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { TeamMember } from '../Registrations/useTeamList';


export const SpeakerSelect = (props: ScoringControlProps) => {
    const { data: { scores, update }, criteria } = props;
    const { round, score } = useScoredRound();
    
    
    const team = round?._id && score?.position ? round.players[score.position] : null;
    const speakers = ((round?._id && score?.position && round.info?.members && round.info.members[score.position]) || []) as TeamMember[];

    const speakersX = speakers.map(m => ({ label: m.fullname, value: `${m.fullname}, ${team?.display_name}`}));

    return <Select
                field="speaker"
                row={{ speaker: scores[criteria?._id || ""]}}
                schema={{
                  type: FieldType.select, 
                  label: " ",
                  values: speakersX,
                  valueDict: speakersX.reduce((r,x) => ({ ...r, [x.value]: x.label }), {})
                }}
                onChange={(o,c) => update({ [criteria?._id || ""]: c.speaker })}
                />
}
