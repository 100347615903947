import { css } from "@emotion/react";
import { Theme } from "@mui/material";

export const createBreakPointSteps = (width: number) => ({
  down: `(max-width: ${width}px)`,
  up: `(min-width: ${width+1}px)`,
})

export interface TypographySettings {
  fontFamily: string,
  fontFamilyHeaders?: string,
  fontSizes: Record<"base" | "base_sm" | "h1" | "h2" | "h3" | "h4", string>,
  fontWeights?: Record<"base" | "h1" | "h2" | "h3" | "h4", number>;
}

export const BaseMuiThemeComponentsSettings: Partial<Theme["components"]> = {
  MuiTextField: {
    defaultProps: {
      variant: "standard",
    },
  },

  MuiFormControl: {
    defaultProps: {
      variant: "standard",
    },
  },

  MuiSelect: {
    defaultProps: {
      variant: "standard",
    },
  },

  MuiButton: {
    defaultProps: {
      color: "inherit",
    },
  },
}

export const BaseThemeSettings = {
  colors: {
    primary: "#16A5D4",
    primaryAlt1: "#33CCFF",
    primaryAlt2: "#19C4FC",
    secondary: "#FC8D19",
    secondaryAlt1: "#B05900",

    background: "#ffffff",
    backgroundAlt1: "#dbf0f7",
    backgroundAlt2: "#3f6470",

    shadow: {
      panel: "#00000080",
    },
  },

  sizes: {
    spacing: {
      block: "1rem",
      contentBottom: { l: "30px", s: "15px" },
      contentSides: { l: "30px", s: "15px" },
    },
    shadow: {
      button: "0px 0.5px 3px -1px",
      panel: "0px 1px 6px -3px",
    },
    borderRadius: {
      panel: 8,
    },

    frontPage: {
      padding: {
        horizontal: {
          s: "20px",
          l: "100px",
        },
        vertical: {
          s: "10px",
          sExtra: "40px",
          l: "100px",
          llow: "50px",
        },
      }
    },
  },

  breakpoints: {
    lowPage: "(min-width: 960px) and (max-height: 650px)",
    s: createBreakPointSteps(380),
    sm: createBreakPointSteps(660),
    md: createBreakPointSteps(960),
    l: createBreakPointSteps(1200),
    xl: createBreakPointSteps(1500),
  },

  typography: {
    fontFamily: "Roboto",
    fontFamilyHeaders: "Roboto",
    fontSizes: {
      base: "16px",
      base_sm: "14px",
      h1: "2.4rem",
      h2: "2rem",
      h3: "1.6rem",
      h4: "1.2rem",
    },
    fontWeights: {
      base: 400,
      h1: 600,
      h2: 600,
      h3: 600,
      h4: 600,
    } as (Record<"base" | "h1" | "h2" | "h3" | "h4", number> | undefined)
  },
}

export interface GlobalStyleProps {
  typography: TypographySettings;
  breakpoints: typeof BaseThemeSettings["breakpoints"]
}

export const createGlobalStyle = (settings: GlobalStyleProps) => css`
  html, body {
    margin: 0;
    font-size: ${settings.typography.fontSizes.base};
    font-weight: ${settings.typography.fontWeights?.base || 400};
    @media ${settings.breakpoints.sm.down} {
      font-size: ${settings.typography.fontSizes.base_sm};
    }
  }

  * {
    font-family: ${settings.typography.fontFamily};
  }

  h1, h2, h3, h4 {
    font-family: ${settings.typography.fontFamilyHeaders || settings.typography.fontFamily};

    span {
      font-family: ${settings.typography.fontFamilyHeaders || settings.typography.fontFamily};
    }
  }

  h1 {
    font-size: ${settings.typography.fontSizes.h1};
    font-weight: ${settings.typography.fontWeights?.h1 || 600}
  }
  h2 {
    font-size: ${settings.typography.fontSizes.h2};
    font-weight: ${settings.typography.fontWeights?.h2 || 600}
  }
  h3 {
    font-size: ${settings.typography.fontSizes.h3};
    font-weight: ${settings.typography.fontWeights?.h3 || 600}
  }
  h4 {
    font-size: ${settings.typography.fontSizes.h4};
    font-weight: ${settings.typography.fontWeights?.h4 || 600}
  }
`;

export const createMuiTypographySettings = (settings: Pick<GlobalStyleProps, "typography">) => ({
  fontFamily: settings.typography.fontFamily,
  h1: { fontSize: settings.typography.fontSizes.h1, fontFamily: settings.typography.fontFamilyHeaders, fontWeight: settings.typography.fontWeights?.h1 || 600 },
  h2: { fontSize: settings.typography.fontSizes.h2, fontFamily: settings.typography.fontFamilyHeaders, fontWeight: settings.typography.fontWeights?.h2 || 600 },
  h3: { fontSize: settings.typography.fontSizes.h3, fontFamily: settings.typography.fontFamilyHeaders, fontWeight: settings.typography.fontWeights?.h3 || 600 },
  h4: { fontSize: settings.typography.fontSizes.h4, fontFamily: settings.typography.fontFamilyHeaders, fontWeight: settings.typography.fontWeights?.h4 || 600 },
});
