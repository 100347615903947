import React from 'react';
import { PowerEditorBase, PowerEditorBaseProps } from '../PowerDoc';
import { ColumnsPlugin } from '../PowerDoc/plugins/Columns';
import { ExpanderPlugin } from '../PowerDoc/plugins/Expander';
import { useMediaFilesPlugin } from '../PowerDoc/plugins/MediaFiles';
import { TablesPlugin } from '../PowerDoc/plugins/Tables';
import { TilesPlugin } from '../PowerDoc/plugins/Tiles';
import { PowerEditorSettingsProvider } from '../PowerDoc/PowerEditorSettingsContext';
import { useDocumentationPagesPlugin } from './DocumentationPagesEditorPlugin';

const DocumentationEditorUnwrapped = (props: PowerEditorBaseProps) => {
    const mediaFilesPlugin = useMediaFilesPlugin();
    const pagesPlugin = useDocumentationPagesPlugin();

    return (
        <PowerEditorBase
            {...props}
            plugins={[
                TilesPlugin,
                ColumnsPlugin,
                TablesPlugin,
                ExpanderPlugin,
                mediaFilesPlugin,
                pagesPlugin,
            ]}
            autoFocus={!props.viewMode}
            />
    );
}


export const DocumentationEditor = (props: PowerEditorBaseProps) => {
    return (
        <PowerEditorSettingsProvider EditorComponent={DocumentationEditorUnwrapped}>
            <DocumentationEditorUnwrapped {...props} />
        </PowerEditorSettingsProvider>
    );
}
