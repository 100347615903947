import { useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";

export interface Page {
    _id: string;
    slug: string;
    title: string;
    content: any;
}

export interface CreatePage extends Pick<Page, "title" | "slug" > {

}

export const PageApiPath = "/api/cms-page";

export const usePageList = () => {
    const data = useCrudUnpagedList<Page>(PageApiPath, {
        defaultView: "all",
        filterBy: n => `${n.title} ${n.slug} ${n._id}`,
    });

    const { schema } = useSingleSchema(`${PageApiPath}/uiconfig`);

    const newItem = useNewItem<CreatePage, Page>(PageApiPath, {
        title: "",
        slug: "",
    });

    return {
        ...data,
        create: () => {
            newItem.startEditing();
            return Promise.resolve({} as Page);
        },
        newItem,
        schema,
    }
}
