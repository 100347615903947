import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import AppRouter from './AppRouter';
import { theme } from './theme';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ResetPasswordContainer from './components/RestorePassword/ResetPasswordContainer';
import RestorePasswordContainer from './components/RestorePassword/RestorePasswordContainer';
import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { LoginContainer } from './components/Auth/LoginContainer';
import { FormatsProvider } from './toolympus/components/schemed';
import { ConfirmationDialogProvider } from './toolympus/components/primitives/ConfirmationDialog';
import { RoundScoringForm } from './components/Arbitrators/RoundScoringForm';
import { ArbitratorAvailabilityPage } from './components/Arbitrators/ArbitratorAvailabilityPage';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="en" defaultLocale="en">
                    <FormatsProvider formats={{ date: "DD.MM.YYYY" }}>
                        <Router>
                            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                                <ErrorBoundary>
                                  <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                                        <ConfirmationDialogProvider>
                                            <Switch>
                                                <Route path='/login' component={LoginContainer} />
                                                <Route path='/reset' component={ResetPasswordContainer} />
                                                <Route path='/restore-password' component={RestorePasswordContainer} />
                                                <Route path="/score/:arbiter_id/:round_id" component={RoundScoringForm} />
                                                <Route path="/availability/:arbiter_id" component={ArbitratorAvailabilityPage} />
                                                <Route path='/' component={AppRouter} />
                                            </Switch>
                                        </ConfirmationDialogProvider>
                                  </DatesLocalizationProvider>
                                </ErrorBoundary>
                            </SnackbarProvider>
                        </Router>
                    </FormatsProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}

export default App;