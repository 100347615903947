import { useCallback, useState } from "react";
import { useValidationErrors, ValidationErrors } from "../components/schemed";
import { apiFetch, FetchTypes, FetchTypesX } from "./core";

interface BaseProps<ItemType, ResultType> {
    isEditing: boolean;
    item: ItemType | null;
    update: (changes: Partial<ItemType>) => void;
    save: (extraChanges?: Partial<ItemType>) => Promise<ResultType>;
    cancel: () => void;
    isLoading: boolean;
    errors: ValidationErrors;
}

export interface NewItemProps<ItemType, ResultType> extends BaseProps<ItemType, ResultType> {
    startEditing: (defaults?: Partial<ItemType>) => void;
    createdItem: ResultType | null;
    resetCreated: () => void;
}

interface NewItemConfig<ItemType> {
    onChange?: (o: ItemType, changes: Partial<ItemType>) => Partial<ItemType>;
    httpMethod?: FetchTypesX;
    autoStartEdit?: boolean;
}

export const useNewItem = <ItemType, ResultType, >(apiPath: string, defaultValue: ItemType, cfg?: NewItemConfig<ItemType>): NewItemProps<ItemType, ResultType> => {
    const [item, setItem] = useState<ItemType | null>(cfg?.autoStartEdit ? defaultValue : null);
    const [createdItem, setCreatedItem] = useState<ResultType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const errors = useValidationErrors();

    const startEditing = useCallback(
      (defaults?: Partial<ItemType>) => setItem({ ...defaultValue, ...defaults }),
      [defaultValue]);

    return {
        item,
        isEditing: !!item,
        startEditing,
        update: c => {
            if(item) {
                const changes = cfg?.onChange ? cfg.onChange(item, c) : c;
                setItem({ ...item, ...changes })
            }
        },
        cancel: () => setItem(null),
        save: () => {
            if(item) {
                setIsLoading(true);
                errors.clearErrors();
                return apiFetch<ResultType>(apiPath, cfg?.httpMethod || "POST", item)
                    .then(r => {
                        setIsLoading(false);
                        setCreatedItem(r);
                        setItem(null)
                        return r;
                    })
                    .catch(e => {
                        setIsLoading(false);
                        errors.handleErrors(e);
                        throw e;
                    });
            } else {
                return Promise.reject({ response: { error_code: "error.general"}});
            }
        },
        createdItem,
        resetCreated: () => setCreatedItem(null),
        isLoading,
        errors,

    };
}

export interface EditItemProps<ItemType> extends BaseProps<ItemType, ItemType> {
    startEditing: (item: ItemType) => void;
    setItem: (item: ItemType) => void;
    hasChanges?: boolean;
    changes?: Partial<ItemType>;
    isSaved?: boolean;
}

export const useEditItem = <ItemType, >(apiPath: string, idField: keyof ItemType): EditItemProps<ItemType> => {
  return useEditItem2({
    getApiPath: item => `${apiPath}/${item[idField]}`,
  });
}

interface EditItem2Cfg<ItemType> {
  getApiPath?: (item: ItemType) => string;
  httpMethod?: FetchTypesX;
  save?: (item: ItemType, changes: Partial<ItemType>) => Promise<ItemType>;
  startWith?: ItemType;
  dontResetOnSave?: boolean;
}

export const useEditItem2 = <ItemType, >(cfg: EditItem2Cfg<ItemType>): EditItemProps<ItemType> => {
  const [item, setItem] = useState<ItemType | null>(cfg.startWith || null);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [changes, setChanges] = useState<Partial<ItemType>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const errors = useValidationErrors();

  const getApiPath = cfg.getApiPath || (() => { throw new Error("useEditItem2: neither save nor getApiPath not provided")});

  return {
      item,
      setItem,
      isEditing: !!item,
      startEditing: (item: ItemType) => { setIsSaved(false); setItem(item); setChanges({}); },
      update: c => {
          if(item) {
              setItem({ ...item, ...c });
              setChanges({ ...changes, ...c });
          }
      },
      cancel: () => { setItem(null); setChanges({}); },
      save: (extraChanges?: Partial<ItemType>) => {
          if(item) {
              let adjItem = item;
              let adjChanges = changes;
              if(extraChanges) {
                adjItem = { ...item, ...extraChanges };
                adjChanges = { ...changes, ...extraChanges };
                setItem(adjItem);
              }
              setIsLoading(true);
              errors.clearErrors();
              return (cfg.save ? cfg.save(adjItem, changes) : apiFetch<ItemType>(getApiPath(adjItem), cfg.httpMethod || FetchTypes.PUT, adjChanges))
                  .then(r => {
                      setIsLoading(false);
                      if(!cfg.dontResetOnSave) {
                        setItem(null);
                      }
                      setChanges({});
                      setIsSaved(true);
                      return r;
                  })
                  .catch(e => {
                      setIsLoading(false);
                      errors.handleErrors(e);
                      throw e;
                  });
          } else {
              return Promise.reject({ response: { error_code: "error.general"}});
          }
      },
      isLoading,
      errors,
      changes,
      hasChanges: Object.keys(changes).length > 0,
      isSaved,

  };
}


export interface OpenItemProps<ItemType> {
    item: ItemType | null;
    isOpen: boolean;
    cancel: () => void;
    open: (item: ItemType) => void;
}

export const useOpenItem = <ItemType,>(): OpenItemProps<ItemType> => {
    const [item, setItem] = useState<ItemType | null>(null);

    return {
        item,
        isOpen: !!item,
        cancel: () => setItem(null),
        open: item => setItem(item),
    }
}
