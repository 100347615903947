import React from 'react';
import { DateSlotsWrapper, TimeslotsWrapper } from './TimeslotsConfigurationEditor';
import { useTimeslotsSelection } from './useTimeslotsSelection';
import { Chip, Typography } from '@mui/material';
import { useFormats } from '../../schemed';
import moment from 'moment';
import { Check } from '@mui/icons-material';

interface Props {
  available: string[] | undefined;
  selected: string[] | undefined;
  update: (v: string[]) => void;
  dateFormat?: string;
  timeFormat?: string;
  disabled?: boolean;
  wrapperStyle?: React.CSSProperties;
}

export const TimeslotsSelection = (props: Props) => {
  const data = useTimeslotsSelection(props.available, props.selected, props.update);
  const formats = useFormats();
  
  return (
    <TimeslotsWrapper style={props.wrapperStyle}>
      {data.dates.map(d => (
        <React.Fragment key={d}>
          <Typography className="date-label">{moment(d).format(props.dateFormat || formats.date)}</Typography>

          <DateSlotsWrapper>
            {data.slotsByDate[d].map(slot => (
              <Chip
                className="slot"
                color={data.isSlotSelected(slot) ? "primary" : "default"}
                variant={data.isSlotSelected(slot) ? "filled" : "outlined"}
                icon={data.isSlotSelected(slot) ? <Check /> : undefined}
                label={moment(slot).format(props.timeFormat || "HH:mm")}
                onClick={props.disabled ? () => {} : () => data.toggleSlot(slot)}
                />))}
          </DateSlotsWrapper>
        </React.Fragment>
      ))}
    </TimeslotsWrapper>
  );
}
