import { useLoadedData } from "../hooks/useLoadedData";
import { Schema, useSingleSchema } from "../hooks/useSchema";
import { CrudItemData, useCrudItem } from "./useSimpleCrud";
import { DataItemWithControls, useItemWithControls } from "./useWithControls";

export interface SettingsData<T> extends Omit<CrudItemData<T>, 'remove' | 'data'> {
    settings: T;
    schema: Schema;
    withControls: DataItemWithControls<T>
}

export const useSettings = <T,>(apiPath: string, dflt: T): SettingsData<T> => {
    const data = useCrudItem(apiPath, { defaultValue: dflt });
    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    const withControls = useItemWithControls(data, { schema });

    return {
        ...data,
        settings: data.data,

        schema,
        withControls,
    }
}

export const useSettingsBare = <T,>(apiPath: string, dflt: T): T => {
    const data = useLoadedData<T>(apiPath, dflt);
    return data.data;
}