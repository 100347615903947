import styled from "@emotion/styled";

export const FrontPageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 100px 0;
  max-width: 100svw;
  justify-content: center;

  display: grid;
  grid-template-columns: 200px 600px;
  column-gap: 100px;

  & h1 { font-size: 2rem; }
  & h2 { font-size: 1.8rem; }
  & h3 { font-size: 1.5rem; }
  & h4 { font-size: 1.2rem; }
  & h5 { font-size: 1rem; }

  
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
    padding: 100px;
    
    & > img {
      width: 200px;
      justify-self: center;
    }
  }

  @media (max-width: 850px) {
    padding: 50px 20px;
  }
`;


export const FrontPageForm = styled.form`
  width: 100%;
  margin-top: 110px;

  @media (max-width: 850px) {
    margin-top: 2rem;
  }
`;