import { useIntl } from "react-intl";
import { useGridSettings } from ".";
import { apiFetch, downloadFile, FetchTypes } from "../../../api/core";
import { useActionWithConfirmation } from "../../../api/useAction";
import { useLoadedData } from "../../../hooks/useLoadedData";
import { DialogState, useDialogState } from "../../primitives/Dialogs";
import { useTextFilter, WithTextFilter } from "../../schemed/Filtering/useTextFilter";
import { ExtraAction } from "../../StatusAction";
import { Player, Round } from "./types";
import { ArbitersAssignment, useArbitersAssignment } from "./useArbitersAssignment";
import { EditRoundData, useEditRound } from "./useEditRound";
import { GridConfiguration, Stage } from "./useGridConfiguration";
import { GridStageResults, useGridStageResults } from "./useGridStageResults";
import { MassEditRoundData, useMassEditRounds } from "./useMassEditRounds";
import { SelectArbiter, useSelectArbiter } from "./useSelectArbiter";
import { SelectPlayer, useSelectPlayer } from "./useSelectPlayer";
import { EditItemProps, useEditItem2 } from "../../../api/useNewItem";
import { toMap } from "../../../api/data";

export interface GridStageData extends WithTextFilter {
    isLoading: boolean;
    stage: Stage;
    rounds: Round[];

    actions: ExtraAction[];
    advancedActions: ExtraAction[];
    isActionExecuting: boolean;

    editRound: EditRoundData;
    selectPlayer: SelectPlayer;
    selectArbiter: SelectArbiter;
    autoAssignArbiters: ArbitersAssignment;
    massEditRounds: MassEditRoundData;

    results: GridStageResults;
    scoresQRCodesDialog: DialogState;
    playersAssignmentReview: EditItemProps<{ pairsText: string }>;
    assignPlayersByOrder: EditItemProps<{ pairsText: string }>;
}


const preparePlayerAssignmentsExport = (rounds: Round[], players: Player[]) => {
  const playerOrder = toMap(players || [], p => p._id, p => players.indexOf(p)+1);
  
  return rounds.map(r => [r.players[1]?._id, r.players[2]?._id])
    .map(ps => ps.map(pid => pid ? playerOrder[pid] : undefined))
    .filter(ps => ps.every(pi => pi !== undefined))
    .map(ps => ps.join(" "))
    .join("\n");
}


export const useGridStage = (apiPath: string, stage_code: string, haltLoad?: boolean): GridStageData => {
    const roundApiPath = `${apiPath}/stage/${stage_code}/round`;
    const configuration = useLoadedData<GridConfiguration>(apiPath, { stages: [] } as unknown as GridConfiguration, !haltLoad);
    const rounds = useLoadedData<Round[]>(roundApiPath, [], !haltLoad);
    const filter = useTextFilter<Round>(r => `${r.title} ${r.number} ${Object.values(r.players).map(p => p.display_name).join(" ")} ${Object.values(r.arbiters).map(a => a.display_name).join(" ")}`)

    const stage = (configuration.data.stages.find(s => s.code === stage_code) || {}) as Stage;
    const roundsPerTeam = stage.kind === "rating" ? configuration.data.rating_player_rounds_cnt : 1;

    const scoresQRCodesDialog = useDialogState();
    const results = useGridStageResults(apiPath, stage_code, filter.filter, stage.is_launched);

    const massEditRounds = useMassEditRounds(roundApiPath, { onSave: () => rounds.reload() });


    const selectPlayer = useSelectPlayer(apiPath, {
        onSave: r => rounds.reload(),
        roundsPerTeam: roundsPerTeam,
        stage_code: stage.code,
    });
    const selectArbiter = useSelectArbiter(apiPath, {
        onSave: r => rounds.reload(),
    });
    const editRound = useEditRound(roundApiPath, selectPlayer, selectArbiter);
    
    const { formatMessage } = useIntl();

    const validate = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/validation?type=${stage.is_launched ? 'all' : 'pre-launch'}`, FetchTypes.GET)
            .then(() => { rounds.reload(); configuration.reload(); }),
            {
                title: formatMessage({ id: "contests.rounds.config.stages.validate" }),
                skipConfirmation: true,
            },
    )

    const clearProblems = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/validation/clear`, FetchTypes.GET)
            .then(() => { rounds.reload(); configuration.reload(); }),
            {
                title: formatMessage({ id: "contests.rounds.config.stages.clear_problems" }),
                skipConfirmation: true,
            },
    )

    const autoAssignPlayers = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/round/assign-players`, FetchTypes.POST)
            .then(() => rounds.reload()),
            {
                title: formatMessage({ id: "contests.rounds.config.stages.assign_players" }),
                confirmationHint: formatMessage({ id: "contests.rounds.config.stages.assign_players_confirmation" }),
            }
        );

    const autoAssignArbiters = useArbitersAssignment(apiPath, stage_code, {
      onSave: () => rounds.reload(),
      selectedRounds: massEditRounds.selectedRounds,
    });

    const clearPlayers = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/clear?players=true`, FetchTypes.POST)
            .then(() => rounds.reload()),
            {
                title: formatMessage({ id: "contests.rounds.config.stages.clear_players" }),
                confirmationHint: formatMessage({ id: "contests.rounds.config.stages.clear_players_confirmation" }),
            }
        );
    const clearArbiters = useActionWithConfirmation(
        () => {
          const body = massEditRounds.selectedRounds?.length
            ? { rounds_ids: massEditRounds.selectedRounds.map(r => r._id) }
            : undefined;
          
          return apiFetch(`${apiPath}/stage/${stage_code}/clear?arbiters=true`, FetchTypes.POST, body)
            .then(() => rounds.reload());
        },
        {
            title: formatMessage({ id: "contests.rounds.config.stages.clear_arbiters" }),
            confirmationHint: formatMessage({ id: "contests.rounds.config.stages.clear_arbiters_confirmation" }),
        }
      );
    const clearStage = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/clear?rounds=true`, FetchTypes.POST)
            .then(() => rounds.reload()),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.clear_stage" }),
            confirmationHint: formatMessage({ id: "contests.rounds.config.stages.clear_stage_confirmation" }),
        }
    );

    const launchStageForced = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/launch?force=true`, FetchTypes.POST)
            .then(() => { rounds.reload(); configuration.reload(); }),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.launch" }),
            confirmationHint: formatMessage({ id: "contests.rounds.config.stages.launch_forced_confirmation" }),
        }
    );

    const launchStage = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/launch`, FetchTypes.POST)
            .then(() => { rounds.reload(); configuration.reload(); })
            .catch(e => { rounds.reload(); configuration.reload(); launchStageForced.run(); }),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.launch" }),
            confirmationHint: formatMessage({ id: "contests.rounds.config.stages.launch_confirmation" }),
        }
    );

    const unlaunchStage = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/launch`, FetchTypes.DELETE)
            .then(() => { rounds.reload(); configuration.reload(); }),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.unlaunch" }),
            confirmationHint: formatMessage({ id: "contests.rounds.config.stages.unlaunch_confirmation" }),
        }
    );
    

    const finishStageForced = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/finish?force=true`, FetchTypes.POST)
            .then(() => { rounds.reload(); configuration.reload(); }),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.finish" }),
            confirmationHint: formatMessage({ id: "contests.rounds.config.stages.finish_forced_confirmation" }),
        }
    );

    const finishStage = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/finish`, FetchTypes.POST)
            .then(() => { rounds.reload(); configuration.reload(); })
            .catch(e => { rounds.reload(); configuration.reload(); finishStageForced.run(); }),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.finish" }),
            confirmationHint: formatMessage({ id: "contests.rounds.config.stages.finish_confirmation" }),
        }
    );

    const unfinishStage = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/finish`, FetchTypes.DELETE)
            .then(() => { rounds.reload(); configuration.reload(); }),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.unfinish" }),
            confirmationHint: formatMessage({ id: "contests.rounds.config.stages.unfinish_confirmation" }),
        }
    );

    const calcResults = useActionWithConfirmation(
        () => apiFetch(`${apiPath}/stage/${stage_code}/result`, FetchTypes.POST)
            .then(() => { rounds.reload(); results.reload(); }),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.calc_results" }),
            skipConfirmation: true,
        }
    );

    const exportData = useActionWithConfirmation(
        () => downloadFile(`${apiPath}/stage/${stage_code}/export`, `${stage.code}_export.xlsx`),
        {
            title: formatMessage({ id: "contests.rounds.config.stages.export" }),
            skipConfirmation: true,
        }
    );


    const assignPlayersByOrder = useEditItem2<{ pairsText: string}>({
      save: item => {
        const pairs = item.pairsText.split("\n").map(l => l.trim()).filter(l => !!l)
          .map(l => l.split(/\s+/))
          .map(([a,b]) => [+a,+b]);
        return apiFetch(`${apiPath}/stage/${stage_code}/round/assign-players-order`, FetchTypes.POST, { pairs })
            .then(() => { rounds.reload(); return item; });
      }
    });

    const playersAssignmentReview = useEditItem2<{ pairsText: string }>({
      save: item => Promise.resolve(item),
    })

    const isActionExecuting = autoAssignPlayers.isRunning
        || assignPlayersByOrder.isLoading
        || clearPlayers.isRunning
        || clearArbiters.isRunning
        || clearStage.isRunning;

    const [
        validateX,
        clearProblemsX,
        autoAssignPlayersX,
        clearPlayersX,
        clearArbitersX,
        clearStageX,
        launchStageX,
        unlaunchStageX,
        finishStageX,
        unfinishStageX,
        calcResultsX,
        exportDataX,
    ] = [
        validate,
        clearProblems,
        autoAssignPlayers,
        clearPlayers,
        clearArbiters,
        clearStage,
        launchStage,
        unlaunchStage,
        finishStage,
        unfinishStage,
        calcResults,
        exportData,
    ].map(a => ({ label: a.title, action: a.run, disabled: !a.canRun }));

    const { qr_codes_enabled } = useGridSettings();

    const actions = [
        validateX,
        clearProblemsX,
        ...(stage.is_launched ? [] : [
                autoAssignPlayersX,
                {
                  label: formatMessage({ id: "contests.rounds.config.stages.assign_players_by_order" }),
                  action: () => assignPlayersByOrder.startEditing({ pairsText: "" }),
                  disabled: isActionExecuting,
                },
                {
                  label: formatMessage({ id: "contests.rounds.config.stages.assign_arbiters" }),
                  action: autoAssignArbiters.open,
                  disabled: isActionExecuting,
                },
                clearPlayersX,
                clearArbitersX,
                clearStageX,
                launchStageX,
            ]),
        ...(stage.is_launched && !stage.is_finished ?
            [
                calcResultsX,
                finishStageX,
                unlaunchStageX,
            ] : []),
        ...(stage.is_finished ?
            [
                unfinishStageX,
            ] : [
                ...(qr_codes_enabled ? [{
                    label: formatMessage({ id: "contests.rounds.config.stages.qr_codes" }),
                    action: scoresQRCodesDialog.open,
                }] : []),
            ]),
        exportDataX,
    ];

    const advancedActions: ExtraAction[] = [
      {
        label: "Grid visualizer",
        action: () => playersAssignmentReview.startEditing({ pairsText: "" }),
      },
      {
        label: "Assignments review",
        action: () => playersAssignmentReview.startEditing({ pairsText: preparePlayerAssignmentsExport(rounds.data, selectPlayer.availablePlayers || []) }),
      }
    ];

    

    return {
        isLoading: configuration.isLoading || rounds.isLoading,
        stage,
        rounds: filter.filterData(rounds.data),
        ...filter,

        editRound: {
            ...editRound,
            save: () => editRound.save().then(x => { rounds.reload(); return x; }),
        },

        actions,
        advancedActions,
        isActionExecuting,

        selectPlayer,
        selectArbiter,
        autoAssignArbiters,
        massEditRounds,
        scoresQRCodesDialog,
        playersAssignmentReview,
        results,
        assignPlayersByOrder,
    }
}
