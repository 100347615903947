import { useEffect, useState } from "react"

export const useLocalStorageString = <T extends string = string>(key: string, defaultValue?: T) => {
  const [value,setValue] = useState<T | undefined>(defaultValue);

  useEffect(() => {
    setValue(localStorage.getItem(key) as T || defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const update = (v: T | undefined) => {
    setValue(v);
    if(v === null || v === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, v);
    }
  }

  return {
    value,
    update,
  }
}
