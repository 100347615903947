import React, { useState } from 'react';
import { Settings } from "@mui/icons-material";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { CustomElement } from '../../../../../slate';
import { Editor, Element } from 'slate';
import { BlockSettingsDialog } from './BlockSettingsDialog';

export const useBlockSettingsPlugin = (): EditorPlugin => {
    const [element, setElement] = useState<CustomElement | null>(null);

    const openBlockSettings = (editor: Editor) => {
        const [currentBlock] = Editor.nodes(editor, {
            match: n => !Editor.isEditor(n) && Element.isElement(n),
        });
        if(currentBlock) {
            setElement(currentBlock[0] as Element);
        }
    }

    return {
        key: "block-settings",
        commands: [{
            name: "block-settings",
            hotkey: "alt+shift+c",
            invoke: openBlockSettings,
            menu: {
                section: "formatting",
                label: "Block configuration",
                icon: <Settings />,
                label_id: "powerdoc.plugins.block_settings.title",
            }
        }],
        dialogs: (
            <BlockSettingsDialog
                element={element}
                close={() => setElement(null)}
            />),
    }
}