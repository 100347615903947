import { IconButton } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import React, { ReactNode, useState } from "react"
import { apiFetch, FetchTypes } from "../../api/core";
import { useLoadedData, LoadedData } from "../../hooks/useLoadedData";
import { RecordTagsEditPopup } from "./RecordTagsEditPopup";
import { TagsDisplay } from "./TagDisplay";
import { Tag } from './useTagManagement';

export interface RecordsTags {
    openEdit: (recordId: string) => void;
    displayForRecord: (recordId: string) => ReactNode;
    popup: ReactNode;
    reloadTags?: () => void;
    reloadAllTags?: () => void;
    allTags?: Tag[];
}

export const useAllTags = (apiPath: string, dontLoad?: boolean): LoadedData<Tag[]> => useLoadedData<Tag[]>(`${apiPath}`, [], !dontLoad);

export const useTags = (apiPath: string, entity: string): RecordsTags => {
    const { data: allTags, reload: reloadAllTags } = useAllTags(apiPath);
    const { data: tags, setData: setTags, reload: reloadTags } = useLoadedData<Record<string, Tag[]>>(`${apiPath}/entity/${entity}`, {});
    const [selectedRecordId, setSelectedRecordId] = useState<string | null>(null);

    const removeTag = (tag: Tag) => {
        if(selectedRecordId) {
            apiFetch(`${apiPath}/record/${entity}/${selectedRecordId}/${tag._id}`, FetchTypes.DELETE)
                .then(() => setTags({
                    ...tags,
                    [selectedRecordId]: (tags[selectedRecordId] || []).filter(t => t._id !== tag._id)
                }));
        }
    }

    const addTag = (tag: Tag) => {
        if(selectedRecordId) {
            apiFetch(`${apiPath}/record/${entity}/${selectedRecordId}/${tag._id}`, FetchTypes.POST)
                .then(() => setTags({
                    ...tags,
                    [selectedRecordId]: [...(tags[selectedRecordId] || []), tag].sort((a,b) => a.label > b.label ? 1 : -1),
                }));
        }
    }

    const addNewTag = (label: string) => {
        if(selectedRecordId) {
            apiFetch<Tag>(apiPath, FetchTypes.POST, { label }).then(addTag).then(() => { reloadTags(); reloadAllTags(); });
        }
    }

    const popup = (
        <RecordTagsEditPopup
            isOpen={!!selectedRecordId}
            close={() => setSelectedRecordId(null)}
            selectedTags={(selectedRecordId && tags[selectedRecordId]) || []}
            availableTags={allTags}
            addTag={addTag}
            removeTag={removeTag}
            addNewTag={addNewTag}
            clear={() => { }}
        />);

    const openEdit = setSelectedRecordId;

    return {
        displayForRecord: (recordId: string) => {
            const recordTags = tags[recordId] || [];
            return recordTags.length > 0 ?
                <TagsDisplay onClick={() => openEdit(recordId)} tags={recordTags} />
                : <IconButton size="small" onClick={() => openEdit(recordId)} style={{ minWidth: 0, justifySelf: "flex-start" }}><EditOutlined /></IconButton>
        },
        popup,
        openEdit,
        reloadTags,
        reloadAllTags,
        allTags,
    }
}