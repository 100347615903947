import { apiFetch, downloadFile } from "../../toolympus/api/core";
import { useTableComments } from "../../toolympus/components/Comments";
import { RegistrationsData } from "../../toolympus/components/Contests/Registrations";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";


export interface ConferenceRegistration {
    _id: string;
    email: string;
    occupation: string;
    fullname: string;
    source: string;
}

export interface ConferenceRegListData extends RegistrationsData<ConferenceRegistration> {
}

const ApiPath = '/api/admin/conference';

export const useConfRegsList = (): ConferenceRegListData => {
    const data = useLoadedData<ConferenceRegistration[]>(ApiPath, []);
    const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

    const filter = useTextFilter<ConferenceRegistration>(t => `${t.fullname} ${t.email} ${t.occupation}`, "", { exactFields: ["_id"] });
    const comments = useTableComments("/api/comment", "conf_reg", { isRemoveAllowed: () => false });

    const remove = (t: ConferenceRegistration) => apiFetch(`${ApiPath}/${t._id}`, "delete").then(() => data.reload());

    const exportData = () => downloadFile(`${ApiPath}/export`, "vis_conference_registrations.xlsx");

    return {
        ...data,
        data: filter.filterData(data.data),

        remove,
        exportData,

        ...filter,
        schema,
        comments,
    }
}
