import { useTimeslotsGrouped } from "./useTimeslotsConfigurationEdit";

export const useTimeslotsSelection = (available: string[] | undefined, selected: string[] | undefined, update: (v: string[]) => void) => {
  const { dates, slotsByDate } = useTimeslotsGrouped(available);

  const toggleSlot = (slot: string) => {
    const current = selected || [];
    if(available?.includes(slot)) {
      const updated = current.includes(slot) ? current.filter(s => s !== slot) : [...current, slot];
      updated.sort();
      update(updated);
    }
  }

  const addSlot = (slot: string) => {
    const current = selected || [];
    if(available?.includes(slot) && !current.includes(slot)) {
      const updated = [...current, slot];
      updated.sort();
      update(updated);
    }
  }

  const removeSlot = (slot: string) => {
    const current = selected || [];
    if(available?.includes(slot) && current.includes(slot)) {
      update(current.filter(x => x !== slot));
    }
  }

  const isSlotSelected = (slot: string) => (selected || []).includes(slot);

  return {
    dates,
    slotsByDate,
    addSlot,
    removeSlot,
    toggleSlot,
    isSlotSelected,
    available,
  }
}
