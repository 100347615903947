import React from 'react';
import { RegistrationsListPage } from '../../toolympus/components/Contests/Registrations';
import { useConfRegsList } from './useConfRegsList';

export const ConferenceRegListPage = () => {
    const data = useConfRegsList();

    return (
        <RegistrationsListPage
            data={data}
            fields={{
                defaultFields: [
                    "email",
                    "fullname",
                    "occupation",
                    "personal_info_consent",
                    "source",
                    "created_datetime",
                ],
                extraSettings: {
                    created_datetime: { utcToLocal: true, },
                },
                schema: data.schema,
                storageKey: "_vis_conf_fields",
            }}
            title="Conference&nbsp;Registrations"
            itemDescription={r => `${r.fullname} (${r.source}, ${r.email})`}
            />);
}
