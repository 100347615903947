import React from 'react';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { RegistrationReviewPopup } from '../../toolympus/components/Contests/Registrations';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { Arbiter } from './useArbiterList';
import { ArbiterReview } from './useArbiterReview';
import { PlaceholderField } from '../../toolympus/components/schemed';
import { AccessTime, FileCopyOutlined, LinkOutlined } from '@mui/icons-material';
import { DeleteButton, useCopyText } from '../../toolympus/components/primitives';
import { ContestSettings } from '../Configuration/ControlPage';
import { TimeslotsSelection } from '../../toolympus/components/Contests/Timeslots';

interface Props {
    data: ArbiterReview;
    settings: ContestSettings;
    remove: (team: Arbiter) => Promise<Arbiter>;
}

export const ArbiterReviewPopup = (props: Props) => {
    const { controls, data, update, settings } = props.data;

    const copyText = useCopyText();

    return (
        <RegistrationReviewPopup
            data={props.data}
            dialogProps={{ maxWidth: "md"}}
            title="Arbitrator"
            titleActions={<>
              {props.remove && <DeleteButton
                remove={() => props.remove(props.data.data as Arbiter).then(x => { props.data.close(); return x; })}
                title="Remove arbitrator"
                preventGoBack
                />}
            </>}>
            <FormGrid key="identity" columns="1fr 1fr">
                {controls([
                    ["email", {
                        controlProps: {
                            endAdornment: (
                                <IconButton
                                    size="small"
                                    onClick={() => copyText(data?.email || "")}
                                    >
                                    <FileCopyOutlined />
                                </IconButton>)
                        }
                    }],
                    [PlaceholderField],

                    ["access_code", {
                        readOnly: true,
                        controlProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => copyText(`https://vis.mootcourt.ru/availability/${data?._id}?code=${data?.access_code}`)}
                                    >
                                    <AccessTime />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={() => copyText(`https://vismoot.ru/arbitrator-rounds?user_id=${data?._id}&user_code=${data?.access_code}`)}
                                    >
                                    <LinkOutlined />
                                </IconButton>
                              </InputAdornment>)
                        }
                    }],
                    ["created_datetime", { readOnly: true, utcToLocal: true, }],
                ])}
            </FormGrid>

            <FormGrid key="details" columns="1fr">
                {controls([
                    ["fullname"],
                    ["phone"],
                    ["country_new"],
                    ["timezone"],
                    ["work_place"],
                    ["work_position"],
                    ["affiliation_universities", { autoRows: true }],
                    ["affiliation_teams", { autoRows: true }],
                    ["moot_experience", { autoRows: true }],
                    ["other_comments", { autoRows: true }],
                    ["will_attend_conference"],
                ])}
                
                
                <Typography variant="h6" style={{ marginTop: "1rem"}}>Time slots</Typography>
                <TimeslotsSelection
                  available={settings.arbitrators_timeslots_new}
                  selected={data?.timeslots_preferrence_new}
                  update={v => update({ timeslots_preferrence_new: v })}
                  />
            </FormGrid>
        </RegistrationReviewPopup>
    );
}
