import React from 'react';
import { Checkbox } from '@mui/material';
import { FieldType } from '../../hooks/useSchema';
import { DisplayProps } from './FormControlProps';
import {
    SelectDisplay,
    DictionarySelectDisplay,
    SelectMultiDisplay,
    DictionarySelectMultiDisplay
} from './Select';
import { MarkdownDisplay } from './Markdown';
import { TimeDisplay } from './TimeDisplay';
import { JsonDisplay } from './JsonEdit';
import { NumberDisplay } from './NumberEdit';
import { TextLongDisplay, TextDisplay } from './TextDisplay';

export const TableDisplay = (props: DisplayProps) => {
    const { row, field, schema } = props;

    const value = row[field];
    switch (schema?.type) {
        case FieldType.bool:
            return <Checkbox style={{ justifyContent: 'center' }} disabled checked={row[field]} />;
        case FieldType.select:
            return <SelectDisplay {...props} />;
        case FieldType.dictionarySelect:
            return <DictionarySelectDisplay {...props} />;
        case FieldType.multiselect:
            return <SelectMultiDisplay {...props} />;
        case FieldType.dictionarySelectMulti:
            return <DictionarySelectMultiDisplay {...props} />;
        case FieldType.date:
            return <TimeDisplay {...props} />
        case FieldType.datetime:
            return <TimeDisplay {...props} />
        case FieldType.number:
            return <NumberDisplay {...props} />
        case FieldType.markdown:
            return <MarkdownDisplay text={value} />;
        case FieldType.json:
            return <JsonDisplay value={value} />
        case FieldType.textlong:
            return <TextLongDisplay {...props} />;
        default:
            return <TextDisplay {...props} />;
    }
};
