import React from 'react';
import { AvailableTagsProvider } from '../../Tags';
import { GridConfigurationPage, GridConfigurationProps } from './GridConfigurationPage';
import { useGridConfiguration } from './useGridConfiguration';

interface Props extends Omit<GridConfigurationProps, 'data'> {
    apiPath: string;
    tagsApiPath: string;
}

export const GridConfigurationContainer = (props: Props) => {
    const data = useGridConfiguration(props.apiPath);

    return (
        <AvailableTagsProvider apiPath={props.tagsApiPath}>
            <GridConfigurationPage {...props} data={data} />
        </AvailableTagsProvider>
    );
}
