import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { useContestSettings } from "../Configuration/ControlPage"
import { Arbiter } from "../Registrations/useArbiterList";

export const useArbitratorAvailability = (arbitratorId: string, accessCode: string) => {
  const contestState = useContestSettings();

  const data = useEditItem2<Pick<Arbiter, "timeslots_preferrence_new">>({
    dontResetOnSave: true,
    getApiPath: () => `/api/register/arbiter/${arbitratorId}?code=${accessCode}`,
    startWith: { timeslots_preferrence_new: [] },
  });

  return {
    contestState,
    available_slots: contestState.arbitrators_timeslots_new,
    ...data,
  }
}
