import React, { useState } from 'react';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { AppWrapper, Sidebar, AppContentSimple, Menu, Routes } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import { MediaLibProvider } from './toolympus/components/medialib';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser | null>(token ? decodeToken(token) as DefaultUser : null);
    const userContext = UserContext;

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const routesToRender = InnerRoutes.filter(r => r.resolveHidden === undefined || r.resolveHidden(user?.roles || []));

    const menuItems = routesToRender
        .filter(r => !r.hidden && r.title)
        .map(r => { return { title: r.title || "", icon: r.icon, path: r.path, alsoActivateForPath: r.alsoActivateForPath } });

    const top = (<Logo version={2} />);
    const menu = (<Menu menuItems={menuItems} />);
    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Exit' />
            </ListItem>
        </List>
    )

    return (
        <userContext.Provider value={{ user: user, setUser: setUser }}>
            <AppWrapper>
                <Sidebar
                    top={top}
                    menu={menu}
                    bottom={bottom}
                    open={true}
                    onClose={() => {}}
                />
                <MediaLibProvider spaceId="1" apiPath="/api/medialib" getFilepath={mf => `/media/${mf.filename}`}>
                  <DictionariesProvider apiPath="/api/dictionaries">
                    <AppContentSimple fit100Height scrollBarsStyle={{ size: 5 }} style={{ padding: '1rem' }}>
                        <Routes routes={routesToRender} notFoundRedirect="/admin/teams" />
                    </AppContentSimple>
                  </DictionariesProvider>
                </MediaLibProvider>
            </AppWrapper>
        </userContext.Provider>
    );
}

export default AppRouter;

