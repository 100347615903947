import { ReactNode } from "react";
import { EditItemProps, useEditItem } from "../../../api/useNewItem"
import { useItemWithControls } from "../../../api/useWithControls";
import { FieldType, Schema } from "../../../hooks/useSchema";
import { FieldDefinition } from "../../schemed";
import { useGridSettings } from "./GridSettingsContext";
import { Round } from "./types"
import { SelectArbiter } from "./useSelectArbiter";
import { SelectPlayer } from "./useSelectPlayer";

export interface EditRoundData extends EditItemProps<Round> {
    schema: Schema;
    controls: (fields: FieldDefinition[]) => ReactNode;
    meetingControls: (fields: FieldDefinition[]) => ReactNode;
    selectPlayer: SelectPlayer;
    selectArbiter: SelectArbiter;
}

export const RoundSchema = {
    title: { type: FieldType.text, label_id: "contests.rounds.round.labels.title" },
    number: { type: FieldType.number, label_id: "contests.rounds.round.labels.number" },
    time: { type: FieldType.datetime, label_id: "contests.rounds.round.labels.time" },
    description: { type: FieldType.textlong, label_id: "contests.rounds.round.labels.description" },
    winner: { type: FieldType.number, label_id: "contests.rounds.round.labels.winner" },
};

export const RoundMeetingInfoSchema = {
    join_url: { type: FieldType.text, label_id: "contests.rounds.round.labels.meeting_join_url" },
}

export const useEditRound = (apiPath: string, selectPlayer: SelectPlayer, selectArbiter: SelectArbiter): EditRoundData => {
    const data = useEditItem<Round>(apiPath, "_id");
    const { conference_kind } = useGridSettings();

    const schema = RoundSchema;
    const meetingSchema = RoundMeetingInfoSchema;

    const { controls } = useItemWithControls(
        { data: data.item || {} as Round, update: data.update, errors: data.errors },
        { schema });

    const { controls: meetingControls } = useItemWithControls({
        data: data?.item?.meeting_info || {} as any,
        update: (changes: any) => data.update({ meeting_info: { ...(data?.item?.meeting_info || {}), kind: conference_kind, ...changes }}),
        errors: data.errors.getInnerErrors(["meeting_info"]),
    }, {
        schema: meetingSchema,
    });

    return {
        ...data,
        schema,
        controls,
        meetingControls,

        selectPlayer: {
            ...selectPlayer,
            open: t => selectPlayer.open({ ...t, onSave: r => data.setItem(r) }),
            clearPlayer: t => selectPlayer.clearPlayer({ ...t, onSave: r => data.setItem(r) }),
        },
        selectArbiter: {
            ...selectArbiter,
            open: t => selectArbiter.open({ ...t, onSave: r => data.setItem(r) }),
            clearArbiter: t => selectArbiter.clearArbiter({ ...t, onSave: r => data.setItem(r) }),
        },
    }
}
