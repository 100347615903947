import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGridSettings } from '.';
import { apiFetch, FetchTypes } from '../../../api/core';
import { useLoadedData } from '../../../hooks/useLoadedData';
import { FieldType } from '../../../hooks/useSchema';
import { Dialog } from '../../primitives/Dialogs';
import { SearchField } from '../../primitives/SearchField';
import { TableForFields } from '../../schemed';
import { useTextFilter, WithTextFilter } from '../../schemed/Filtering/useTextFilter';
import { useAvailableTags, TagsDisplay } from '../../Tags';
import { Player } from './types';
import { Round } from './types';
import { Button } from '@mui/material';

interface Props extends WithTextFilter {
    isOpen: boolean;
    close: () => void;
    players: Player[];
    saveSelected: (p?: Player) => void;
    selected: Player | null;
    setSelected: (p: Player | null) => void;
    roundsPerTeam?: number;
}

const SelectPlayerPopup = (props: Props) => {
    const { isOpen, close, players, filter, setFilter, selected, setSelected, saveSelected } = props;
    const tags = useAvailableTags();

    const settings = useGridSettings();
    const extraColumns = settings.player_extra_columns || [];

    return (
        <Dialog
            isOpen={isOpen} close={close} maxWidth="md" fullWidth
            dialogTitle={<FormattedMessage id="contests.rounds.player.select" />}
            actions={<>
              <Button onClick={() => close()}><FormattedMessage id="common.close" /></Button>
              <Button variant="contained" color="primary" disabled={!selected} onClick={() => saveSelected()}><FormattedMessage id="common.select" /></Button>
            </>}
            titleActions={<>
              <SearchField filter={filter} setFilter={setFilter} noButton autoFocus doSearch={() => {
                  if(selected) {
                      saveSelected();
                  } else if (players.length === 1) {
                      saveSelected(players[0]);
                  }
              }} />
            </>}
            >

            <TableForFields
                data={players}
                fields={[
                    ["display_name"],
                    ["rounds_assigned"],
                    ["description"],
                    ["tags"],
                    ...extraColumns.map(([f]) => [f] as [string]),
                ]}
                schema={{
                    display_name: { type: FieldType.text, label_id: "contests.rounds.player.labels.display_name"},
                    rounds_assigned: { type: FieldType.text, label_id: "contests.rounds.player.labels.rounds_assigned"},
                    description: { type: FieldType.text, label_id: "contests.rounds.player.labels.description"},
                    tags: { type: FieldType.text, label_id: "contests.rounds.player.labels.tags"},
                }}
                fieldElement={f => {
                    if(f === "tags") {
                        return r => <TagsDisplay wrap tags={tags.fromKnownIDs(r.tags)} />
                    }

                    const extraField = extraColumns.find(([fe]) => fe === f);
                    if(extraField) {
                        return r => <>{extraField[1](r)}</>;
                    }
                }}
                onRowClick={setSelected}
                onDblClick={p => saveSelected(p)}
                rowStyle={p => {
                    if(!!selected && selected._id === p._id) {
                        return { background: "#00cc0030"};
                    } else if (p.rounds_assigned && props.roundsPerTeam && p.rounds_assigned > props.roundsPerTeam) {
                        return { background: "#cc000030"};
                    } else if (p.rounds_assigned && props.roundsPerTeam && p.rounds_assigned === props.roundsPerTeam) {
                        return { background: "#cccccc60"};
                    } else {
                        return {};
                    }
                }}
                />

        </Dialog>
    );
}

interface Target {
    round: Round;
    position: 1 | 2;
    onSave?: (updateRound: Round) => void;
}

export interface SelectPlayer {
    popup: ReactNode;
    open: (target: Target, selected?: Player) => void;
    clearPlayer: (target: Target) => void;
    playersInfo: Record<string, Player>;
    roundsPerTeam?: number;
    availablePlayers?: Player[];
}

interface Config {
    onSave?: (updatedRound: Round) => void;
    roundsPerTeam?: number;
    stage_code?: string;
}

export const useSelectPlayer = (apiPath: string, cfg?: Config): SelectPlayer => {
    const [target, setTarget] = useState<Target | null>(null);
    const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
    const data = useLoadedData<Player[]>(
        `${apiPath}/players?stage=${target?.round?.stage_code || cfg?.stage_code}`,
        [],
        !!target || !!cfg?.stage_code);
    const filter = useTextFilter<Player>(p => `${p.display_name} ${p.description}`);

    const close = () => { setTarget(null); filter.setFilter(""); setSelectedPlayer(null); };

    const doPlayersUpdate = (target: Target, player: Player | null)  => {
        const players = {
            ...target.round.players,
            [target.position]: player ? { _id: player._id } : null,
        };
        return apiFetch<Round>(`${apiPath}/stage/${target?.round?.stage_code}/round/${target?.round?._id}`, FetchTypes.PUT, {
            players,
        })
        .then(round => {
            data.reload();
            if(cfg?.onSave) {
                cfg.onSave(round);
            }
            if(target?.onSave) {
                target.onSave(round);
            }
            close();
            return round;
        });
    }

    const saveSelected = (p: Player | undefined) => {
        const player = p || selectedPlayer
        if(!!target && !!player) {
            return doPlayersUpdate(target, player);
        }
    }

    

    const clearPlayer = (target: Target) => {
        return doPlayersUpdate(target, null);
    }

    const popup = (
        <SelectPlayerPopup
            isOpen={!!target}
            close={close}
            filter={filter.filter}
            setFilter={filter.setFilter}
            players={filter.filterData(data.data)}
            saveSelected={saveSelected}
            selected={selectedPlayer}
            setSelected={setSelectedPlayer}
            roundsPerTeam={cfg?.roundsPerTeam}
            />)

    return {
        popup,
        open: (t,p) => {
            setTarget(t);
            if(p) {
                setSelectedPlayer(p);
            }
        },
        clearPlayer,
        playersInfo: data.data.reduce((r,p) => ({ ...r, [p._id]: p }), {}),
        roundsPerTeam: cfg?.roundsPerTeam,
        availablePlayers: data.data,
    };
}