import { useSnackbar } from "notistack";
import { useState } from "react";
import { useIntl } from "react-intl";
import { apiFetch } from "../../../api/core";
import { mergeSchema, Schema } from "../../../hooks/useSchema";
import { useGridSettings } from "./GridSettingsContext";
import { Round } from "./types";
import { RoundMeetingInfoSchema, RoundSchema } from "./useEditRound";

type RoundChanges = Pick<Round, "title" | "description" | "time"> & {
    isTitleSet: boolean;
    isDescriptionSet: boolean;
    isTimeSet: boolean;

    join_url: string;
    isJoinUrlSet: boolean;
    meeting_info: { kind: string, join_url: string };
}

export interface MassEditRoundData {
    selectedRounds: Round[];
    isRoundSelected: (r: Round) => boolean;
    setRoundSelected: (r: Round, isSelected: boolean) => void;
    selectMultiple: (rs: Round[]) => void;
    clear: () => void;

    startEdit: () => void;
    isEditing: boolean;
    changes: Partial<RoundChanges>;
    update: (changes: Partial<RoundChanges>) => void;
    hasChanges: boolean;
    cancel: () => void;
    save: () => void;
    isSaving: boolean;
    schema: Schema;
}

interface Config {
    onSave: () => void;
}

export const useMassEditRounds = (apiPath: string, cfg?: Config): MassEditRoundData => {
    const [selectedRounds, setSelectedRounds] = useState<Round[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [changes, setChanges] = useState<Partial<RoundChanges>>({});

    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();
    const { conference_kind } = useGridSettings();

    const update = (v: Partial<RoundChanges>) => {
        if(v.title) {
            v.isTitleSet = true;
        }
        if(v.time) {
            v.isTimeSet = true;
        }
        if(v.description) {
            v.isDescriptionSet = true;
        }
        if(v.join_url) {
            v.isJoinUrlSet = true;
        }
        setChanges({ ...changes, ...v });
    }

    const hasChanges = !!(changes.isTitleSet || changes.isDescriptionSet || changes.isTimeSet || changes.isJoinUrlSet);
    
    const isRoundSelected = (r: Round) => !!selectedRounds.find(rx => rx._id === r._id);
    const setRoundSelected = (r: Round, v: boolean) => {
        if(v && !isRoundSelected(r)) {
            setSelectedRounds(rs => [...rs, r]);
        } else if (!v) {
            setSelectedRounds(rs => rs.filter(rx => rx._id !== r._id));
        }
    }

    const selectMultiple = (rounds: Round[]) => setSelectedRounds(rounds);

    const clear = () => setSelectedRounds([]);

    const startEdit = () => {
        setIsEditing(true);
    }

    const cancel = () => {
        setIsEditing(false);
    }

    const save = () => {
        if(selectedRounds.length > 0 && hasChanges) {
            const actualChanges: Partial<RoundChanges> = {};
            if(changes.isTitleSet) {
                actualChanges.title = changes.title;
            }
            if(changes.isTimeSet) {
                actualChanges.time = changes.time;
            }
            if(changes.isDescriptionSet) {
                actualChanges.description = changes.description;
            }
            if(changes.isJoinUrlSet && conference_kind) {
                actualChanges.meeting_info = { kind: conference_kind, join_url: changes.join_url || "" };
            }

            setIsSaving(true);
            Promise.all(selectedRounds.map(r => {
                const changes = actualChanges.title ?
                    { ...actualChanges, title: actualChanges.title.replace(/{number}/gi, () => r.number.toString())}
                    : actualChanges;
                return apiFetch(`${apiPath}/${r._id}`, "put", changes);
            }))
                .then(() => {
                    setChanges({});
                    setIsSaving(false);
                    setIsEditing(false);
                    if(cfg?.onSave) {
                        cfg.onSave();
                    }
                    enqueueSnackbar(formatMessage({ id: "contests.rounds.config.stages.edit_rounds.success_message" }), { variant: "success", autoHideDuration: 5000 });
                })
                .catch(e => {
                    setIsSaving(false);
                    throw e;
                });
        }
    }

    return {
        selectedRounds,
        isRoundSelected,
        setRoundSelected,
        selectMultiple,
        clear,

        startEdit,
        isEditing,
        changes,
        update,
        hasChanges,
        cancel,
        save,
        isSaving,
        schema: mergeSchema(RoundSchema, RoundMeetingInfoSchema),
    }
}
