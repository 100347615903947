import React, { ComponentType, useMemo } from "react";
import { RenderElementProps } from "slate-react"
import { CustomFieldsBlockElementType, extractCustomBlockFields } from "./CustomFieldsBlockElement";
import { EditorPlugin } from "../../slate/PowerEditorConfig";

type ComponentsMap = Record<string, ComponentType<RenderElementProps>>;

export const CustomFieldsBlockWrap = (props: RenderElementProps & { components: ComponentsMap }) => {
  const fields = extractCustomBlockFields(props.element);
  const { block_type } = fields;
  const Comp = props.components[block_type || ""];

  if(!Comp) {
    return null;
  }

  return <Comp {...props} />

}

export const useCustomFieldsViewerPlugin = (components: ComponentsMap): EditorPlugin => {
  const BlockMemo = useMemo(
    () => (props: RenderElementProps) => <CustomFieldsBlockWrap components={components} {...props} />,
    [components]);

  return {
    key: "custom-fields-viewer",
    customBlocks: {
      [CustomFieldsBlockElementType]: BlockMemo,
    }
  }
}
