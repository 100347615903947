import React from 'react';
import { useHistory } from 'react-router-dom';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { SimpleDialog, FormGrid, SimpleList, SlugEdit } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { CreatePage, Page, usePageList } from './usePageList';

export const PageList = () => {
    const data = usePageList();
    const history = useHistory();

    return (<>
        <SimpleList
            title="Pages"
            data={data}
            schema={data.schema}
            getLinkValue={n => `/page/${n._id}`}
            searchAuto
            searchAutoFocus
            fields={[
                ["title"],
                ["slug"],
            ]}
            linkFields={["title", "slug"]}
            />
        <CreatePagePopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`/page/${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

interface CreatePageProps extends NewItemProps<CreatePage, Page> {
    schema: Schema;
}

const CreatePagePopup = (props: CreatePageProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreatePage, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle="Create page"
            save={() => save()}
            noFullscreen
            >
            <FormGrid columns="1fr">
                {controls([
                    ["title"],
                ])}

                <SlugEdit
                    field="slug"
                    row={item}
                    onChange={(o,c) => update(c)}
                    schema={schema["slug"]}
                    sourceField="title"
                    />
            </FormGrid>
        </SimpleDialog>);
}
