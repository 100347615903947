import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Round } from './types';

export type TimezoneSetting = "local" | "utc";

export const ProblemsContainer = styled.ul`
    width: 100%;
    list-style: none;
    border-radius: 8px;
    background: #cc000020;
    color: ${props => props.theme.palette.error.dark};
    padding: 0.5rem 0.75rem;
    box-sizing: border-box;
`;

export const ProblemMessage = ({ problem, values}: { problem: string, values: any }) => {
    const vals = values && typeof values === "object" ? values : { value: values };
    return <Typography key={problem}><FormattedMessage id={`contests.rounds.config.stages.validation.${problem}`} values={vals} /></Typography>;
}

export const ProblemMessages = ({ problems }: { problems?: Record<string, any>}) => {
    return <>
        {Object.keys(problems || {}).map(p => (
                <ProblemMessage key={p} problem={p} values={(problems || {})[p]} />
            ))}
    </>;
}

export const RoundCSS = (props: any) => css`
    width: 100%;
    min-width: 0;
    position: relative;
    padding: 0.5rem 0.75rem;
    box-sizing: border-box;
    
    box-shadow: 0 0 5px 0 #00000020;
    cursor: pointer;
    

    & > .select-checkbox {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.5rem 0.75rem 0 0;

        & .MuiCheckbox-root {
            padding: 0;
        }
    }

    &.selectable {
        &.selected, &:hover {
            > .select-checkbox {
                display: block;
            }
    
            & > .title-row {
                padding-right: 32px;
            }
        }
    }

    & > .title-row, & > .players-row {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: baseline;
    }

    & > .players-row, & > .players-info-row {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        padding: 0.5rem 0;

        & > :first-child {
            justify-self: flex-start;
        }
        & > :last-child {
            justify-self: flex-end;
            text-align: right;
        }
    }

    & > .judges-row {
        & span {
            padding-bottom: 3px;

            &.highlight {
                border-bottom: 1px solid ${props.theme.palette.primary.main};
            }
        }
    }

    & > .description {
        padding: 0.5rem 0;

        & p {
            font-size: 0.85em;

            &:not(:first-child) {
                min-height: 0.85em;
            }
        }
    }

    & > .conference-row {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        padding: 0.5rem 0;
        gap: 0.5rem;
    }

`;

export const RoundsContainerCSS = (props: any) => css`
    width: 100%;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 0;

    ${props.theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
    }
`;

export const RoundsSelectedText = ({ rounds }: {rounds: Round[]}) => (
    <FormattedMessage id="contests.rounds.config.stages.edit_rounds.rounds_selected" values={{ count: rounds.length, nbrs: rounds.map(r => r.number).sort().join(", ")}} />
);
