import React, { ReactNode } from 'react';
import { ResetPasswordData, useResetPassword } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { SimpleButton } from '../primitives/Buttons';
import { Button, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator } from '../primitives';

interface ResetPasswordFormProps {
    restoreAPIPath: string;
    logoAlt?: string;
    messageOnSubmit?: ReactNode;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props: ResetPasswordFormProps) => {
    const { email, updateEmail, restore, isSaving, isSaved } = useResetPassword(props.restoreAPIPath);

    return (
        <OuterCentralizedForm>
            {!isSaved && <>
                <TextField
                    label={<FormattedMessage id="resetPassword.email"/>}
                    value={email}
                    onChange={(ev) => updateEmail(ev.target.value || '')}
                />
                <SimpleButton
                    label={<FormattedMessage id="resetPassword.submit"/>}
                    action={restore}
                    isLoading={isSaving}
                />
            </>}
            {isSaved && (props.messageOnSubmit || <Typography><FormattedMessage id="resetPassword.success"/></Typography>)}
        </OuterCentralizedForm>
    )
};

export const ResetPasswordFields = (props: { data: ResetPasswordData, messageOnSubmit?: ReactNode, noButton?: boolean }) => {
  const { email, updateEmail, isSaving, isSaved } = props.data;

  return (
      <>
        {!isSaved && <>
            <TextField
                label={<FormattedMessage id="resetPassword.email"/>}
                value={email}
                onChange={(ev) => updateEmail(ev.target.value || '')}
            />
          {!props.noButton && <Button
            type='submit'
            variant='contained'
            color='primary'
            startIcon={isSaving ? <LoadingIndicator color="inherit" sizeVariant="s" /> : undefined}>
            <FormattedMessage id="resetPassword.submit"/>
          </Button>}
        </>}
        {isSaved && (props.messageOnSubmit || <Typography><FormattedMessage id="resetPassword.success"/></Typography>)}
      </>
  )
};
