import { apiFetch, downloadFile, FetchTypes } from "../../toolympus/api/core";
import { useTableComments } from "../../toolympus/components/Comments";
import { RegistrationsData } from "../../toolympus/components/Contests/Registrations";
import { useRegistrationsTabs } from "../../toolympus/components/Contests/Registrations/useRegistrationsTabs";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useTags } from "../../toolympus/components/Tags";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { TeamReview, useTeamReview } from "./useTeamReview";

export interface TeamMember {
  fullname: string;
}

export interface Team {
    _id: string;
    number: string;
    country: string;
    university: string;
    email: string;
    exclude?: boolean;
    access_code: string;
    teammembers_new?: TeamMember[];
}

export interface TeamListData extends RegistrationsData<Team> {
    review: TeamReview;
}

export const TeamApiPath = '/api/admin/team';

export const useTeamList = (): TeamListData => {
    const data = useLoadedData<Team[]>(TeamApiPath, []);
    const { schema } = useSingleSchema(`${TeamApiPath}/uiconfig`);

    const filter = useTextFilter<Team>(t => `${t.number} ${t.email} ${t.country} ${t.university}`, "", { exactFields: ["_id"] });
    const tags = useTags("/api/conflict/tag", "team");
    const comments = useTableComments("/api/comment", "team", { isRemoveAllowed: () => false });

    const approval = {
        isRegistrationApproved: (t: Team) => !t.exclude,
        update: (t:Team, approve: boolean, comment?: string) => {
            return apiFetch<Team>(`${TeamApiPath}/${t._id}`, FetchTypes.PUT, { exclude: !approve })
                .then(t => {
                    data.reload();
                    return t;
                })
        }
    };

    const tabs = useRegistrationsTabs({ approval, queryParam: "tab" });

    const remove = (t: Team) => apiFetch(`${TeamApiPath}/${t._id}`, "delete").then(() => data.reload());

    const exportData = () => downloadFile(`${TeamApiPath}/export`, "vis_teams.xlsx");

    const review = useTeamReview(() => data.reload());

    return {
        ...data,
        data: filter.filterData(data.data.filter(t => tabs.activeFilter(t))),

        remove,
        approval,
        exportData,

        ...filter,
        schema,
        tags,
        comments,
        tabs,

        review,
    }
}
