import React from "react";
import { EmojiPeople, DirectionsRun, SportsSoccer, Settings, ToggleOn, VideocamOutlined, Archive, InfoOutlined, DescriptionOutlined, PhotoAlbumOutlined } from "@mui/icons-material";
import { TeamListPage } from "./components/Registrations/TeamListPage";
import { MenuRoute } from "./toolympus/components/frame";
import { ArbiterListPage } from "./components/Registrations/ArbiterListPage";
import { RoundsConfigurationPage } from "./components/Configuration/RoundsConfigurationPage";
import { ControlPage } from "./components/Configuration/ControlPage";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { ConferenceRegListPage } from "./components/Registrations/ConferenceRegListPage";
import { SnapshotsManagement } from "./components/Configuration/SnapshotsManagement";
import { Documentation } from "./components/Configuration/Documentation";
import { CmsMediaLibPage, PageList, PagePage } from "./components/CmsPages";

const InnerRoutes: MenuRoute[] = [
    { path: '/admin/control', component: ControlPage , title: 'Control', icon: <ToggleOn />, hidden: false },
    
    
    { 
        path: '/admin/teams',
        title: 'Teams',
        icon: <EmojiPeople />,
        component: TeamListPage, 
    },
    { 
        path: '/admin/arbitrators',
        title: 'Arbitrators',
        icon: <DirectionsRun />,
        component: ArbiterListPage,
    },
    { 
        path: '/admin/conference',
        title: 'Conference',
        icon: <VideocamOutlined />,
        component: ConferenceRegListPage,
    },
    { 
        icon: <SportsSoccer />,
        path: '/admin/rounds',
        title: 'Rounds',
        component: RoundsConfigurationPage,
    },
    
    
    {
        path: "/page/:id",
        component: PagePage,
        hidden: true,
    },
    {
        path: "/page",
        title: "Site Pages",
        icon: <DescriptionOutlined />,
        component: PageList,
        alsoActivateForPath: p => p.startsWith("/page/"),
    },
    {
        path: "/cmsmedialib",
        title: "Site Media Files",
        icon: <PhotoAlbumOutlined />,
        component: CmsMediaLibPage,
    },


    {
        path: '/admin/system',
        title: 'Configuration',
        component: () => (
            <SystemSubmenu
                scoringSheets={{ apiPath: "/api/scoring/sheet" }}
                queryConsole={{}}
                eventLog={{ apiPath: "/api/event-log" }}
                robud={{}}
                notificationTasks={{ apiPath: "/api/notifications/task" }}
                users={{ userRoles: [{ name: 'Администратор', key: 'admin' }], allowInvites: false, allowPaswordChange: true }}
                dictionaries={{ apiPath: "/api/dictionaries" }}
                extraItems={[
                    {
                        key: "snapshots",
                        label: "Snapshots",
                        icon: <Archive />,
                        items: [{ key: "snapshots", label: "", component: SnapshotsManagement }]
                    },
                    {
                        key: "documentation",
                        label: "Documentation",
                        icon: <InfoOutlined />,
                        items: [{ key: "documentation", label: "", component: Documentation }]
                    }
                ]}
            />),
        icon: <Settings /> },
];

export default InnerRoutes;