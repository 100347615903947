import { OpenRegistrationReviewData, useOpenRegistrationReview } from "../../toolympus/components/Contests/Registrations";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { ContestSettings, useContestSettings } from "../Configuration/ControlPage";
import { Arbiter, ArbiterApiPath } from "./useArbiterList";

export interface ArbiterReview extends OpenRegistrationReviewData<Arbiter> {
    settings: ContestSettings;
}

export const useArbiterReview = (onSaved: (t: Arbiter) => void): ArbiterReview => {
    
    const { schema } = useSingleSchema(`${ArbiterApiPath}/uiconfig`);
    const settings = useContestSettings();

    const data = useOpenRegistrationReview<Arbiter>({
        apiPath: ArbiterApiPath,
        schema,
        onSaved,
        comments: {
            apiPath: "/api/comment",
            entity: "arbiter",
            config: { isRemoveAllowed: () => false },
        }
    });

    return {
        ...data,
        settings,
    }
}