import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@mui/material';
import { ChipTextWrap, TagChip, TagsDisplay } from './TagDisplay';
import { Tag } from './useTagManagement';
import { useTextFilter } from '../schemed/Filtering/useTextFilter';
import { SearchField } from '../primitives/SearchField';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator } from '../primitives';

export interface RecordTagEditProps {
    availableTags: Tag[];
    selectedTags: Tag[];
    addTag: (t: Tag) => void;
    removeTag: (t: Tag) => void;
    addNewTag: (label: string) => void;
    clear: () => void;
    blockAddNew?: boolean;
    wrapTags?: boolean;
    noDisplaySelected?: boolean;
    stretchSearchField?: boolean;
}

interface DialogProps {
    isOpen: boolean;
    close: () => void;
}

const AvailableTagsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    padding: 1rem 0;
`;

const SearchAndCreateWrapper = styled.div`
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
`;

const Separator = styled(Divider)`
    margin: 1rem 0 0.5rem;
`;

export const RecordTagsEdit = (props: RecordTagEditProps) => {
    const {
        availableTags,
        selectedTags,
        removeTag,
        addTag,
        addNewTag,
        blockAddNew,
    } = props;

    const { filter, setFilter, filterData } = useTextFilter<Tag>(t => t.label);

    const filteredTags = filterData(availableTags);

    const canCreate = !blockAddNew && filter.length > 0 && filteredTags.length === 0;

    return (
        <>
            {!props.noDisplaySelected && <TagsDisplay tags={selectedTags} remove={removeTag} wrap={props.wrapTags} />}
            {!props.noDisplaySelected && <Separator />}
            <SearchAndCreateWrapper>
                <SearchField
                    filter={filter}
                    setFilter={setFilter}
                    noButton
                    autoFocus
                    fullWidth={props.stretchSearchField}
                    />
                {canCreate ?
                    <Button
                        color="primary"
                        size="small"
                        onClick={() => { addNewTag(filter); setFilter(""); }}
                        >
                        <FormattedMessage id="tags.create" />
                    </Button> :
                    <div/>}
            </SearchAndCreateWrapper>
            <AvailableTagsWrapper>
                {filteredTags.map(t => {
                    const isSelected = selectedTags.find(ts => ts._id === t._id);
                    return <TagChip
                        key={t._id}
                        label={t.label}
                        variant={isSelected ? "filled" : "outlined"}
                        color={isSelected ? "primary" : "default"}
                        tagColor={isSelected ? undefined : t.color}
                        onClick={isSelected ? () => removeTag(t) : () => addTag(t)}
                        component={props.wrapTags ? ChipTextWrap : "div"}
                        />
                    })}
            </AvailableTagsWrapper>
        </>
    );
}

type EditPopupProps = RecordTagEditProps
  & DialogProps
  & {
    submit?: () => void;
    isSubmitDisabled?: boolean;
    isSubmitting?: boolean;
    submitLabel?: ReactNode;
  };

export const RecordTagsEditPopup = (props: EditPopupProps) => {
    const {
        isOpen,
        close,
        submit,
        isSubmitDisabled,
        isSubmitting,
        submitLabel,
    } = props;

    return (
        <Dialog open={isOpen} onClose={() => close()} fullWidth maxWidth="md">
            <DialogContent>
                <RecordTagsEdit {...props} />
            </DialogContent>
            {props.submit && <DialogActions>
                {isSubmitting && <LoadingIndicator />}
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitDisabled || isSubmitting}
                  onClick={() => submit && submit()}>
                    {submitLabel || <FormattedMessage id="common.submit" />}
                </Button>
              </DialogActions>}
        </Dialog>
    );
}


export const useTagSelection = (availableTags: Tag[]): RecordTagEditProps => {
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

    return {
        addNewTag: () => {},
        blockAddNew: true,
        selectedTags,
        addTag: t => setSelectedTags(ts => [...ts, t]),
        removeTag: t => setSelectedTags(ts => ts.filter(te => te._id !== t._id)),
        clear: () => setSelectedTags([]),
        availableTags,
    }
}
