import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material';
import { Check, PlayArrow } from '@mui/icons-material';
import { ConfirmationDialogProvider } from '../../primitives/ConfirmationDialog';
import { Form } from '../../primitives/Forms';
import { TabPanel, TabsHeader, useTabsState } from '../../primitives/Tabs';
import { GridConfigurationForm } from './GridConfigurationForm';
import { GridStageContainer } from './GridStageContainer';
import { GridConfigurationData, Stage } from './useGridConfiguration';

interface ExtraTabs {
    tabs: [string, ReactNode][];
    components: Record<string, React.ComponentType<{ isActive?: boolean}>>;
}

export interface GridConfigurationProps {
    data: GridConfigurationData;
    extraTabs?: ExtraTabs;
    outerFormClassName?: string;
}

const StageTabHeaderWrapper = styled.span`
    display: flex;
    gap: 0.25rem;
`;

const StageTabHeader = ({ stage }: { stage: Stage }) => {
    const theme = useTheme();

    return <StageTabHeaderWrapper>
        <FormattedMessage id={`contests.rounds.config.stages.names.${stage.code}`} />
        {stage.is_finished && <Check style={{ color: theme.palette.success.main }} />}
        {!stage.is_finished && stage.is_launched && <PlayArrow style={{ color: theme.palette.warning.main }} />}
    </StageTabHeaderWrapper>;
}

export const GridConfigurationPage = (props: GridConfigurationProps) => {
    const { data, apiPath } = props.data;

    const tabs = useTabsState([
        ["config", <FormattedMessage id="contests.rounds.config.labels.configuration" />],
        ...((data.stages || []).map(s => [s.code, <StageTabHeader stage={s} />] as [string, ReactNode])),
        ...(props.extraTabs?.tabs || []),
    ], undefined, 'tab');

    return (
        <Form
            title={<FormattedMessage id="contests.rounds.config.title" />}
            className={props.outerFormClassName}
            >
                <ConfirmationDialogProvider>
                {data.is_launched ?
                    <>
                        <TabsHeader state={tabs} scrollable noMargin />

                        <TabPanel key="config" state={tabs.forTab("config")}>
                            <GridConfigurationForm data={props.data} />
                        </TabPanel>

                        {(data.stages || []).map(s => (
                            <TabPanel key={s.code} state={tabs.forTab(s.code)}>
                                <GridStageContainer apiPath={apiPath} stageCode={s.code} isActive={tabs.current === s.code} />
                            </TabPanel>
                        ))}

                        {Object.keys(props.extraTabs?.components || {}).map(k => {
                            const TabComponent = (props.extraTabs?.components || {})[k];

                            if(TabComponent) {
                                return <TabPanel key={k} state={tabs.forTab(k)}>
                                    <TabComponent isActive={tabs.current === k} />
                                </TabPanel>
                            } else {
                                return null;
                            }
                            
                            
                        })}
                    </>
                    :
                    <GridConfigurationForm data={props.data} />}
                </ConfirmationDialogProvider>
        </Form>
    );
}
