import { apiFetch, FetchTypes } from "../../../api/core";
import { Action, useAction } from "../../../api/useAction";
import { CrudItemData, useCrudItem } from "../../../api/useSimpleCrud";
import { Schema, useSingleSchema } from "../../../hooks/useSchema";

export interface Stage {
    config_code: string;
    kind: string;
    code: string;
    sort_order: number;
    is_launched: boolean;
    is_finished: boolean;
    problems?: Record<string, any>;
}

export interface GridScoreItem {
  _id: string;
  key: string;
  label: string;
  type: "player-sheet" | "player-select" | "player-info";
  sheet?: string;
  extract_pick?: string;
}

export interface GridStageScoringConfig {
  scores: GridScoreItem[];
  result_pick?: string;
  result_aggregate?: string;
  winner_pick?: string;
  winner_aggregate?: string;
}

export type GridStageT = "po_final" |
"po_1_2" |
"po_1_4" |
"po_1_8" |
"po_1_16" |
"rating";

export const GridStages: GridStageT[] = [
  "rating",
  "po_1_16",
  "po_1_8",
  "po_1_4",
  "po_1_2",
  "po_final",
];



export interface GridConfiguration {
    code: string;
    has_playoff: boolean;
    playoff_stages_cnt: number;
    playoff_teams_required: number;
    playoff_team_matching_rule: string;
    playoff_has_third_place_game: boolean;
    playoff_round_title_final: string;
    playoff_round_title_third: string;
    playoff_round_title_po: string;

    has_rating_rounds: boolean;
    rating_group_kind: string;
    rating_grouping_rule: string;
    rating_player_rounds_cnt: number;
    rating_round_title: string;
    players_accepted_cnt: number;
    rating_rounds_cnt: number;

    scoring_config?: Partial<Record<GridStageT | "default", GridStageScoringConfig>> | null | undefined;

    problems: string[];
    is_launched: boolean;
    stages: Stage[];
}

export interface GridConfigurationData extends CrudItemData<GridConfiguration> {
    apiPath: string;
    schema: Schema;
    launch: Action<any>;
    cancel: Action<any>;
}

export const useGridConfiguration = (apiPath: string): GridConfigurationData => {
    const data = useCrudItem<GridConfiguration>(apiPath, {
        defaultValue: { stages: [] as Stage[] } as GridConfiguration,
    });

    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    const launch = useAction(
        () => apiFetch<any>(`${apiPath}/launch`, FetchTypes.POST).then(() => data.reload()),
        !data.data.is_launched && !data.hasChanges);
    const cancel = useAction(
        () => apiFetch<any>(`${apiPath}/launch`, FetchTypes.DELETE).then(() => data.reload()),
        !data.data.is_launched);

    return {
        ...data,
        apiPath,
        schema,
        launch,
        cancel,
    }
}