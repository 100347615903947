import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DialogState } from "../../primitives/Dialogs";
import { Round } from './types';
import QRCode from 'react-qr-code';
import { useFormats } from '../../schemed';
import { utc } from '../../timezone';

interface Props {
    state: DialogState;
    rounds: Round[];
}

interface LinkParams {
    round: string;
    arbiter: string;
    url: string;
    time?: string;
    teams: string;
}

const ContentPage = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    margin-bottom: 50px;

    @media print {
        break-inside: avoid;
        page-break-inside: avoid;
        break-after: always;
        page-break-after: always;
    }
`;

const ItemWrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;

    @media print {
        break-inside: avoid;
        page-break-inside: avoid;
    }
`;


const ButtonX = styled(Button)`
    @media print {
        display: none !important;
    }
`;

export const QRCodesPopup = ({ state, rounds }: Props) => {
    const { formatDatetimeShort } = useFormats();

    const links = !state.isOpen ? [] :
        rounds.map(r => Object.values(r.arbiters)
            .map(a => a ? ({
                arbiter: a.display_name,
                round: r.title,
                url: a.access_url,
                time: r.time ? formatDatetimeShort(utc.toLocal(r.time)) : "",
                teams: `${r.players[1] ? r.players[1].display_name : "-"} vs ${r.players[2] ? r.players[2].display_name : "-"}`,
            }) : null)
            .filter(l => !!l))
            .reduce((result, roundLinks) => [...result, ...roundLinks], [])
            .sort((a,b) => (a?.arbiter || "") < (b?.arbiter || "") ? -1 : 1) as LinkParams[];

    const pages = [];
    const chunk = 9;
    for (let i = 0; i < links.length; i += chunk) {
        pages.push(links.slice(i, i + chunk));
    }

    return (
        <Dialog open={state.isOpen} onClose={state.close} fullScreen>
            <DialogContent>
                {pages.map((pageLinks,i) => (
                    <ContentPage key={i}>
                        {pageLinks.map(({ arbiter, round, time, teams, url }) => (
                            <ItemWrapper key={`${arbiter}${round}`}>
                                <QRCode value={url} />
                                <Typography key="round">{round}{time ? ", " : ""}{time}</Typography>
                                <Typography key="teams">{teams}</Typography>
                                <Typography key="arbiter">{arbiter}</Typography>
                            </ItemWrapper>
                        ))}
                    </ContentPage>
                ))}
            </DialogContent>
            <DialogActions>
                <ButtonX onClick={state.close}><FormattedMessage id="common.close" /></ButtonX>
            </DialogActions>
        </Dialog>
    );
}
