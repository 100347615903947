import React from 'react';
import { useSettings, useSettingsBare } from '../../toolympus/api/useSettings';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { SaveButton } from '../../toolympus/components/primitives/Buttons';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives/Tabs';
import { ConflictTagManagement } from './ConflictTagManagement';
import { SiteSettingsForm } from './SiteSettingsForm';
import { Save } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { TimeslotsConfigurationEditor } from '../../toolympus/components/Contests/Timeslots';
import { useSaveable } from '../../toolympus/components/primitives';

interface TimeSlot {
    time: string;
    label: string;
}

interface TimeSlotsDate {
    date: string;
    date_display: string;
    slots: TimeSlot[];
}

export interface ContestSettings {
    registration_team_enabled?: boolean;
    registration_arbiter_enabled?: boolean;
    registration_conference_enabled?: boolean;
    arbitrators_timeslots: TimeSlotsDate[];
    arbitrators_timeslots_new?: any;
}

export const SettingsPath = "/api/state";

export const useContestSettings = () => useSettingsBare<ContestSettings>(SettingsPath, { arbitrators_timeslots: [] });

export const ControlPage = () => {
    const settings = useSettings<ContestSettings>(SettingsPath, {} as ContestSettings);

    const tabsState = useTabsState([
        ["state", "State"],
        ["timeslots", "Timeslots"],
        ["conflicts", "Conflict tags"],
        ["site-settings", "Site settings"],
    ], undefined, 'tab');

    useSaveable(settings);

    return (
        <>
            <TabsHeader state={tabsState} scrollable noMargin />

            <TabPanel state={tabsState.forTab("state")}>
                <ActionRow>
                    <OccupyFreeSpace />
                    {settings.isLoading && <LoadingIndicator />}
                    {settings.hasChanges && <SaveButton action={() => settings.save()} />}
                </ActionRow>
                <FormGrid columns="1fr 1fr" forceEvenColumns>
                    {settings.withControls.controls([
                        ["registration_team_enabled"],
                        ["registration_arbiter_enabled"],
                        ["team_profile_edits_enabled"],
                        ["registration_conference_enabled"],
                        ["rounds_results_available"],
                    ])} 
                </FormGrid>
            </TabPanel>

            <TabPanel state={tabsState.forTab("timeslots")}>
              <ActionRow firstItemNoMargin>
                <OccupyFreeSpace />
                <IconButton size="small" color="primary" onClick={() => settings.save()} style={{ visibility: settings.hasChanges ? "visible" : "hidden" }}><Save /></IconButton>
              </ActionRow>

              <TimeslotsConfigurationEditor
                value={settings.settings.arbitrators_timeslots_new}
                update={v => settings.update({ arbitrators_timeslots_new: v })}
                />
            </TabPanel>

            <TabPanel state={tabsState.forTab("conflicts")}>
                <ConflictTagManagement />
            </TabPanel>

            <TabPanel state={tabsState.forTab("site-settings")}>
                <SiteSettingsForm />
            </TabPanel>
        </>
    );
}
