import { IconButton } from '@mui/material';
import { LinkOutlined } from '@mui/icons-material';
import React from 'react';
import { RegistrationReviewPopup } from '../../toolympus/components/Contests/Registrations';
import { DeleteButton, useCopyText } from '../../toolympus/components/primitives';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { TeammembersEdit } from './TeammembersEdit';
import { TeamReview } from './useTeamReview';
import { Team } from './useTeamList';

interface Props {
    data: TeamReview;
    remove: (team: Team) => Promise<Team>;
}

export const TeamReviewPopup = (props: Props) => {
    const { controls, data, update, errors } = props.data;
    const copyText = useCopyText();

    return (
        <RegistrationReviewPopup
            data={props.data}
            dialogProps={{ maxWidth: "md"}}
            title="Team"
            titleActions={<>
              {props.remove && <DeleteButton
                remove={() => props.remove(props.data.data as Team).then(x => { props.data.close(); return x; })}
                title="Remove team"
                preventGoBack
                />}
            </>}>
            <FormGrid key="identity" columns="1fr 1fr">
                {controls([
                    ["number", { readOnly: true }],
                    ["email"],

                    ["access_code", {
                      readOnly: true,
                      controlProps: {
                        endAdornment: (
                            <IconButton
                                size="small"
                                onClick={() => copyText(`https://vismoot.ru/team-profile?user_id=${data?._id}&user_code=${data?.access_code}`)}
                                >
                                <LinkOutlined />
                            </IconButton>)
                    }
                    }],
                    ["created_datetime", { disabled: true, utcToLocal: true, }],
                ])}
            </FormGrid>

            <FormGrid key="details" columns="1fr">
                {controls([
                  ["country_new"],
                  ["university"],
                  ["timezone"],
                ])}

                {!!data &&
                  <TeammembersEdit
                    value={data.teammembers_new || []}
                    update={v => update({ teammembers_new: v})}
                    hasError={errors && errors.fieldHasErrors("teammembers_new")}
                    />}

                {controls([
                  ["teammembers", { autoRows: true, visibleIf: r => r.teammembers && r.teammembers.length }],
                  ["coach"],
                  ["contact_person"],
                  ["phone"],
                  ["will_attend_conference"],
                  ["past_participation"],
                  ["past_participation_details", { autoRows: true }],
                  ["comments", { autoRows: true }],
                  
                  ["rounds_stage", { disabled: true }],          
                ])}
            </FormGrid>
        </RegistrationReviewPopup>
    );
}
