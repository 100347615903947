import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { CallMade, DeleteOutlined, FileCopyOutlined } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { Form, FormGrid, OccupyFreeSpace, SaveButton, SlugEdit, Tooltip } from '../../toolympus/components/primitives';
import { usePageEdit } from './usePageEdit';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { CmsEditor } from './CmsEditor';
import { useActionWithConfirmation } from '../../toolympus/api/useAction';

export const PagePage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const data = usePageEdit(id);
    const { controls } = useItemWithControls(data, { schema: data.schema });
    
    const removeAction = useActionWithConfirmation(() => data.remove(), {
        canRun: !data.isLoading,
        title: `Remove page ${data.data.title}`,
    });
    
    useSaveable(data);

    return (
        <Form
            title="Page"
            headerItems={<>
                <OccupyFreeSpace />
                <Tooltip text="Show on the site">    
                    <IconButton href={`https://vismoot.ru/${data.data.slug}`} target="_blank" rel="noreferrer noopener" size="small">
                        <CallMade />
                    </IconButton>
                </Tooltip>
                <IconButton size="small" onClick={() => data.copyItem().then(n => history.push(`/page/${n._id}`))}>
                    <FileCopyOutlined />
                </IconButton>
                <IconButton size="small" color="primary" onClick={() => removeAction.run()}>
                    <DeleteOutlined />
                </IconButton>
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </>}
            >
            <FormGrid columns="2fr 1fr">
                {controls([["title"]])}
                <SlugEdit
                    field="slug"
                    row={data.data}
                    onChange={(o,c) => data.update(c)}
                    schema={data.schema["slug"]}
                    sourceField="title"
                    />
            </FormGrid>


            <Typography variant="caption" color="textSecondary" style={{ marginTop: "1rem" }}>Content</Typography>
            <CmsEditor
                key={data.data._id}
                content={data.data.content}
                update={v => data.update({ content: v })}
                />
        </Form>
    );
}
