import React from 'react';
import styled from '@emotion/styled'
import { LoginForm } from '../../toolympus/components/auth';
import { useLoginForm } from '../../toolympus/hooks/auth';
import { Logo } from '../Common';

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    & img {
        width: 300px;
    }
`;

export const LoginContainer = () => {
    const signupData = useLoginForm("/api/login", "/");
    return <Wrapper>
        <Logo />
        <LoginForm loginData={signupData} />
    </Wrapper>;
}