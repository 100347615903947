import React, { useMemo } from 'react';
import { useArbitratorAvailability } from './useArbitratorAvailability';
import { useParams } from 'react-router-dom';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { CMSRecord } from '../../toolympus/components/CMS';
import { TimeslotsSelection } from '../../toolympus/components/Contests/Timeslots';
import { Buttons, OccupyFreeSpace } from '../../toolympus/components/primitives';
import { Button, Typography } from '@mui/material';
import { getCustomBlocksData } from '../../toolympus/components/CMS/Usage';
import { PageViewer } from '../CmsPages/PageViewer';
import { FrontPageForm, FrontPageWrapper } from './FrontPage.styles';
import moment from 'moment';
import { Logo } from '../Common';

interface Props {
  
}

export const ArbitratorAvailabilityPage = (props: Props) => {
  const { arbiter_id } = useParams<{ arbiter_id: string }>();
  const code = new URLSearchParams(window.location.search).get("code") || "";
  const data = useArbitratorAvailability(arbiter_id, code);
  const pageInfo = useLoadedData<Partial<CMSRecord>>(`/api/cms-page/slug/arbitrator-timeslots`, {} as any);

  const customBlocksData = useMemo(() => {
    return getCustomBlocksData((pageInfo.data.content as any)?.blocks);    
  }, [pageInfo.data]);

  moment.locale("en");

  return (
    <FrontPageWrapper>
      <Logo />
      
      <FrontPageForm onSubmit={e => { e.preventDefault(); if(!data.isSaved) { data.save(); } }}>
        <Typography variant="h2">{customBlocksData.page_config?.title || "Arbitrator availability"}</Typography>
      
        <PageViewer key={pageInfo.data.slug} content={pageInfo.data?.content} />

        {data.item && <TimeslotsSelection
          dateFormat="DD MMM YYYY (dddd)"
          available={data.available_slots}
          selected={data.item.timeslots_preferrence_new}
          update={v => data.update({ timeslots_preferrence_new: v })}
          disabled={data.isSaved}
          />}

        {!data.isSaved && <Buttons style={{ marginTop: "2rem" }}>
          <OccupyFreeSpace />
          <Button variant="contained" color="primary" type="submit">submit</Button>
        </Buttons>}

        {data.isSaved && <Typography style={{ whiteSpace: "pre-line", textAlign: "center", marginTop: "2rem" }}>
          {customBlocksData.page_config?.success_message}
          </Typography>}
      </FrontPageForm>
    </FrontPageWrapper>
  );
}
