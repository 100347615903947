import React from 'react';
import { IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { FormControl } from '../schemed';
import { FormControlProps } from '../schemed/FormControlProps';
import slugify from 'slugify';

interface Props extends FormControlProps {
    sourceField: string;
}

export const SlugEdit = (props: Props) => {
    const { row, field, onChange, sourceField } = props;

    const generateSlug = () => slugify(row[sourceField] || "", { lower: true });
    
    return (
        <FormControl
            {...props}
            extraProps={{
                ...(props.extraProps || {}),
                controlProps: {
                    endAdornment: (
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => onChange(row, { [field]: generateSlug() })}>
                            <Refresh />
                        </IconButton>) },
            }}
            />
    );
}
