import React, { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import {
    InputLabel,
    MenuItem,
    Select as SelectMUI,
    FormControl as FormControlMaterial,
    FormHelperText,
    Checkbox,
    ListItemText,
    Chip,
    Input,
    Autocomplete,
    TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { expandProps, FormControlBaseProps, FormControlProps, DisplayProps } from './FormControlProps';
import { Dictionaries, useDictionaries } from '../../hooks/useDictionaries';
import { FieldSchema } from '../../hooks/useSchema';
import { FormattedMessage, useIntl } from 'react-intl';
import { Hint } from './Hint';
import { toMap } from '../../api/data';

interface SelectProps {
    values: { value: any, label: ReactNode }[];
    value: any;
    controlProps?: any;
    onChange: (v: any) => void;
    error?: boolean;
    label?: ReactNode;
    hint?: ReactNode;
}

export const SelectControl = (props: SelectProps) => {
    const { label, hint, value, controlProps, values, onChange, error } = props;
    return (
        <FormControlMaterial style={{width: '100%'}} size="small" error={error} required={controlProps?.required}>
            <InputLabel>{label}</InputLabel>
            <SelectMUI
                value={value === null || value === "undefined" ? "" : value}
                {...controlProps}
                onChange={e => onChange(e.target.value)}>
                {(values || []).concat([{ value: null, label: "-"}]).map(({ value, label}) => (
                    <MenuItem key={value || 'undef'} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </SelectMUI>
            {hint && <FormHelperText>{hint}</FormHelperText>}
        </FormControlMaterial>);
}

export const Select = (props: FormControlProps) => {
    const { field, row, label, value, schema, controlProps, extraProps, onChange, error } = expandProps(props);

    const showValues = extraProps?.showValues;
    const labelIdPrefix = extraProps?.labelIdPrefix;

    return (
        <FormControlMaterial style={{width: '100%'}} size="small" error={error} required={controlProps?.required}>
            <InputLabel>{label}</InputLabel>
            <SelectMUI
                value={value || ""}
                {...controlProps}
                onChange={e => onChange(row, { [field]: e.target.value })}>
                {(schema.values || []).concat([{ value: null, label: extraProps?.emptyLabel || "-"}]).map(({ value, label}) => (
                    <MenuItem key={value || 'undef'} value={value}>
                        {showValues ?
                            value :
                            labelIdPrefix ? (value ? <FormattedMessage id={`${labelIdPrefix}.${value}`} /> : label)
                            : label}
                    </MenuItem>
                ))}
            </SelectMUI>
            <Hint schema={schema} extraProps={extraProps} />
        </FormControlMaterial>);
}

export const AutocompleteCombobox = (props: FormControlProps) => {
  const { field, row, value, label, schema, controlProps, extraProps, onChange, error } = expandProps(props);

  const showValues = extraProps?.showValues;
  const labelIdPrefix = extraProps?.labelIdPrefix;

  const { formatMessage } = useIntl();

  const [options, getLabel] = useMemo(() => {
    let gl: (v: string | null) => string = v => v || "";
    if(showValues) {
      // pass
    } else if(labelIdPrefix) {
      gl = v => v ? formatMessage({ id: `${labelIdPrefix}.${v}` }) : "";
    } else if(schema.valueDict) {
      gl = v => v ? schema.valueDict[v] : "";
    } else {
      const dict = toMap(schema.values || [], r => r.value, r => r.label);
      gl = v => v ? dict[v] : "";
    }

    return [
      (schema.values || []).map(v => v.value),
      gl,
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema.values, schema.valueDict, labelIdPrefix, showValues]);

  return (
    <Autocomplete
      disablePortal
      fullWidth
      size="small"
      options={options}
      value={value || ""}
      onChange={(e,newValue) => { onChange(row, { [field]: newValue }); }}
      getOptionLabel={getLabel}
      renderInput={(params) => <TextField error={!!error} {...controlProps} {...params} size="small" margin="none" label={label} />}
      />
  );
}

export const adjustDictionarySelectSchema = (schema: FieldSchema, dictionaries: Dictionaries): FieldSchema => {
    if(!schema?.dictionary) {
        return schema;
    } else {
        const thisDict = dictionaries[schema.dictionary];
        return { ...schema, values: thisDict.values, valueDict: thisDict.valueDict };
    }
}

const adjustDictionarySelectProps = <T extends FormControlBaseProps>(props: T, dictionaries: Dictionaries): T => {
    const { schema } = props;
    const newSchema = adjustDictionarySelectSchema(schema, dictionaries);

    return { ...props, schema: newSchema };
}


export const DictionarySelect = (props: FormControlProps) => {
    const dictionaries = useDictionaries();
    const adjustedProps = adjustDictionarySelectProps(props, dictionaries);
    if(adjustedProps.extraProps?.autoComplete) {
      return <AutocompleteCombobox {...adjustedProps} />
    } else {
      return <Select {...adjustedProps} />
    }
}

export const SelectDisplay = ({ schema, row, field, config }: DisplayProps) => {
    const value = row[field];
    const labelIdPrefix = config?.labelIdPrefix;
    return <>{labelIdPrefix && value ? <FormattedMessage id={`${labelIdPrefix}.${value}`} /> : (schema.valueDict[value] || (!value && config?.emptyLabel) || value)}</>;
}

export const DictionarySelectDisplay = (props: DisplayProps) => {
    const dictionaries = useDictionaries();
    return <SelectDisplay {...adjustDictionarySelectProps(props, dictionaries)} />    
}

export const SelectMultiDisplay = ({ schema, row, field, config }: DisplayProps) => {
    const value = row[field] as string[];
    return <>{(value || []).map(v => schema.valueDict[v] || v).join(", ") || config?.emptyLabel || ""}</>;
}

export const DictionarySelectMultiDisplay = (props: DisplayProps) => {
    const dictionaries = useDictionaries();
    return <SelectMultiDisplay {...adjustDictionarySelectProps(props, dictionaries)} />    
}


export const useMultiStyle = makeStyles({
    inputId: {},
    labelId: {}
});

export const SelectChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SelectChip = styled(Chip)`
  margin: 2px;
`;

type DictionarySelectMultiType = (props: FormControlProps) => JSX.Element

export const DictionarySelectMulti: DictionarySelectMultiType = (props) => {
    const dictionaries = useDictionaries();
    return <MultiSelect {...adjustDictionarySelectProps(props, dictionaries)} />
}


type MultiSelectType = (props: FormControlProps) => JSX.Element

export const MultiSelect: MultiSelectType = (props) => {
    const { inputId, labelId } = useMultiStyle();
    const { field, row, label, value, schema, controlProps, extraProps, error, onChange } = expandProps(props);

    const selected = (value || []) as any[];

    const options = schema.values || [];
    const labelIdPrefix = extraProps?.labelIdPrefix;

    return <FormControlMaterial fullWidth size="small" required={controlProps?.required}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <SelectMUI
            id={inputId}
            labelId={labelId}
            multiple
            error={error}
            {...controlProps}
            value={selected}
            onChange={e => onChange(row, { [field]: e.target.value})}
            input={<Input />}
            renderValue={_ => (
                <SelectChipContainer>
                    {options
                        .filter(({value}) => selected.includes(value))
                        .map(({value, label}) => 
                            <SelectChip
                              key={value || 'undef'}
                              label={labelIdPrefix ? (value ? <FormattedMessage id={`${labelIdPrefix}.${value}`} /> : label) : label} />)
                    }
                </SelectChipContainer>
            )}
        >
            {options.map(({ value, label }) => (
                <MenuItem onClick={e => e.preventDefault()} key={value || 'undef'} value={value}>
                    <Checkbox checked={selected.includes(value)} />
                    <ListItemText primary={labelIdPrefix ? (value ? <FormattedMessage id={`${labelIdPrefix}.${value}`} /> : label) : label} />
                </MenuItem>
            ))}
        </SelectMUI>
        <Hint schema={schema} extraProps={extraProps} />
    </FormControlMaterial>
}
