import React from 'react';
import styled from '@emotion/styled';
import { useTimeslotsConfigurationEdit } from './useTimeslotsConfigurationEdit';
import { useFormats } from '../../schemed';
import { Button, Chip, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { Buttons, FormGrid, SimpleDialog, useWindowHotkey } from '../../primitives';
import { Add } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { DateEdit, TimeEdit } from '../../schemed/TimeEdit';

export const DateSlotsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 6px;

  & .MuiChip-root {
    font-size: 1rem;
  }
`;
DateSlotsWrapper.defaultProps = { className: "timeslots-date-slots" };

export const TimeslotsWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  row-gap: 1rem;
  column-gap: 2rem;
`;
TimeslotsWrapper.defaultProps = { className: "timeslots-wrapper" };


interface Props {
  value: string[];
  update: (v: string[]) => void;
  dateFormat?: string;
  timeFormat?: string;
  noNewSlotHotkey?: boolean;
}

export const TimeslotsConfigurationEditor = (props: Props) => {
  const data = useTimeslotsConfigurationEdit(props.value, props.update);
  const formats = useFormats();

  useWindowHotkey("alt+n", props.noNewSlotHotkey ? (() => {}) : (() => data.addSlotNewDate()))

  return (<>
    <TimeslotsWrapper>
      {data.dates.map(d => (
        <React.Fragment key={d}>
          <Typography className="date-label">{moment(d).format(props.dateFormat || formats.date)}</Typography>

          <DateSlotsWrapper>
            {data.slotsByDate[d].map(slot => (
              <Chip
                className="slot"
                label={moment(slot).format(props.timeFormat || "HH:mm")}
                onDelete={() => data.removeSlot(slot)}
                />))}
            <IconButton size="small" onClick={() => data.addSlotToDate(d)}><Add /></IconButton>
          </DateSlotsWrapper>
        </React.Fragment>
      ))}
    </TimeslotsWrapper>

    <Buttons className="timeslots-buttons">
      <Button size="small" startIcon={<Add />} onClick={() => data.addSlotNewDate()}><FormattedMessage id="contests.timeslots.add" /></Button>
    </Buttons>

    <SimpleDialog
      dialogTitle={<FormattedMessage id="contests.timeslots.add" />}
      saveLabel={<FormattedMessage id="common.add" />}
      isOpen={data.addSlot.isEditing}
      close={() => data.addSlot.cancel()}
      save={() => data.addSlot.save()}
      noFullscreen
      >
      {data.addSlot.item && <FormGrid columns="1fr 1fr" noMargin>
        <DateEdit
          row={data.addSlot.item}
          field="date"
          onChange={(o,c) => data.addSlot.update(c)}
          schema={{ label_id: "contests.timeslots.date" }}
          error={data.addSlot.errors?.fieldHasErrors("date")}
          />

        <TimeEdit
          row={data.addSlot.item}
          field="time"
          onChange={(o,c) => data.addSlot.update(c)}
          schema={{ label_id: "contests.timeslots.time" }}
          error={data.addSlot.errors?.fieldHasErrors("time")}
          extraProps={{ controlProps: { autoFocus: true }}}
          />
      </FormGrid>}
    </SimpleDialog>
  </>);
}
