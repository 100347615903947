import React, { useState } from "react";
import { OpenRegistrationReviewData, Registration, RegistrationReviewData } from ".";
import { apiFetch } from "../../../api/core";
import { useLoadedData } from "../../../hooks/useLoadedData";
import { Schema, useSingleSchema } from "../../../hooks/useSchema";
import { useCommentsWithButton, Settings as CommentsSettings } from "../../Comments";
import { FieldDefinition, FormControlsForFields, useValidationErrors } from "../../schemed";

interface Config<T extends Registration> {
    apiPath: string;
    schema?: Schema;
    onSaved?: (r: T) => void;
    comments?: {
        apiPath: string;
        entity: string;
        config: CommentsSettings;
    }
}

export const useRegistrationReview = <T extends Registration>(id: string | null, cfg: Config<T>): RegistrationReviewData<T> => {
    const { data, isLoading, reload, setData } = useLoadedData<T | null>(`${cfg.apiPath}/${id}`, {} as T, !!id);
    const schemaData = useSingleSchema(`${cfg.apiPath}/uiconfig`, !!id && !cfg.schema);

    const [changes, setChanges] = useState<Partial<T>>({});
    const hasChanges = Object.keys(changes).length > 0;

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const errors = useValidationErrors();

    const commentsSettings = cfg.comments || { apiPath: "", entity: "", config: { suppressLoad: true }};

    const comments = useCommentsWithButton(
        commentsSettings.apiPath,
        commentsSettings.entity,
        id || "",
        { ...commentsSettings.config, suppressLoad: !cfg.comments || !id || commentsSettings.config.suppressLoad  });

    const update = (c: Partial<T>) => {
        if(id && data) {
            setData({ ...data, ...c });
            setChanges(cx => ({ ...cx, ...c }));
        }
    }

    const updateArray = (field: keyof T, idx: number, c: any) => {
        if(data) {
            update({ [field]: (data[field] as unknown as any[] || []).map((x,i) => i === idx ? { ...x, ...c } : x) } as unknown as Partial<T>);
        }
    }

    const updateArrayAdd = (field: keyof T) => {
        if(data) {
            update({ [field]: [...(data[field] as unknown as any[] || []), {}]} as unknown as Partial<T>);
        }
    }

    const updateArrayRemove = (field: keyof T, idx: number) => {
        if(data) {
            update({ [field]: (data[field] as unknown as any[] || []).filter((_,i) => i !== idx)} as unknown as Partial<T>);
        }
    }

    const clear = () => {
        setChanges({});
    }


    const save = () => {
        if(!!id && hasChanges) {
            setIsSaving(true);
            errors.clearErrors();

            apiFetch<T>(`${cfg.apiPath}/${id}`, "put", changes)
                .then(result => {
                    setIsSaving(false);
                    clear();
                    setData({ ...data, ...result });
                    if(cfg.onSaved) {
                        cfg.onSaved(result);
                    }
                })
                .catch(e => {
                    setIsSaving(false);
                    errors.captureErrors(e);
                    throw e;
                });

        }
    }

    const schema = cfg.schema || schemaData.schema;

    const controls = (fields: FieldDefinition[]) => (
        data ?
        <FormControlsForFields
            fields={fields}
            data={data}
            schema={schema}
            onChange={(o,c) => update(c)}
            errors={errors}
            />
        : null
        );

    return {
        data,
        setData,
        isLoading,
        schema,

        changes,
        hasChanges,

        update,
        updateArray,
        updateArrayAdd,
        updateArrayRemove,
        clear,
        reload: () => { reload(); clear(); },

        save,
        isSaving,

        comments: cfg.comments ? comments : undefined,

        controls,
        errors,
    }
}


export const useOpenRegistrationReview = <T extends Registration>(cfg: Config<T>): OpenRegistrationReviewData<T> => {
    const [id, setId] = useState<string | null>(null);
    const data = useRegistrationReview<T>(id, cfg);

    return {
        ...data,
        id,
        open: xid => {
            data.setData(null);
            data.clear();
            setId(xid);
        },
        close: () => {
            setId(null);
            data.clear();
            data.setData(null);
        },
        isOpen: !!id,
    }
}
