import { apiFetch } from "../../toolympus/api/core";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { Page, PageApiPath } from "./usePageList"


export const usePageEdit = (id: string | number) => {
    const data = useCrudItem<Page>(`${PageApiPath}/${id}`, {
        defaultValue: {} as Page,
        returnPath: "/page",
    });

    const copyItem = () => {
        return apiFetch<Page>(
            `${PageApiPath}`,
            "post",
            {
                title: `${data.data.title} (copy)`,
                slug: `${data.data.slug}-copy`,
                content: data.data.content,
            });
    }

    const { schema } = useSingleSchema(`${PageApiPath}/uiconfig`);

    return {
        ...data,
        schema,
        copyItem,
    }
}
