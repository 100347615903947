import React from 'react';
import { RegistrationsListPage } from '../../toolympus/components/Contests/Registrations';
import { PseudoLink2 } from '../../toolympus/components/primitives';
import { TeamReviewPopup } from './TeamReviewPopup';
import { Team, useTeamList } from './useTeamList';

export const TeamListPage = () => {
    const data = useTeamList();

    return (
        <>
            <RegistrationsListPage
                data={data}
                fields={{
                    defaultFields: [
                        "number",
                        "email",
                        "exclude",
                        "university",
                        "country_new",
                        "timezone",
                        "coach",
                        "phone",
                        "contact_person",
                        "will_attend_conference",
                        "past_participation",
                        "past_participation_details",
                        "comments",
                        "personal_info_consent",
                        "rounds_stage",
                        "created_datetime",
                    ],
                    extraSettings: {
                        created_datetime: { utcToLocal: true, },
                    },
                    schema: data.schema,
                    storageKey: "_vis_teams_fields",
                }}
                title="Teams"
                itemDescription={t => `${t.number} (${t.email})`}
                review={data.review}
                fieldElement={f => {
                    switch(f) {
                        case "number":
                        case "university":
                          return (row,_,orig) => <PseudoLink2 border onClick={() => data.review.open(row._id)}>{orig}</PseudoLink2>;
                        case "teammembers_new":
                          return row => <p style={{ whiteSpace: "pre-line"}}>{(row.teammembers_new || []).map(m => `- ${m.fullname}`).join("\n")}</p>
                    }
                }}
                />
                <TeamReviewPopup data={data.review} remove={data.remove as (t: Team) => Promise<Team>} />
            </>);
}
