import React, { ReactNode } from 'react';
import { Button, DialogProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { OpenRegistrationReviewData, Registration } from './types';
import { Dialog } from '../../primitives/Dialogs';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';

interface Props<T extends Registration> {
    data: OpenRegistrationReviewData<T>;
    
    title: ReactNode;
    titleActions?: ReactNode;
    extraActions?: ReactNode;
    children: ReactNode;

    dialogProps?: Partial<Omit<DialogProps, 'open' | 'onClose' | 'children'>>;
}

export const RegistrationReviewPopup = <T extends Registration>(props: Props<T>) => {
    const { isOpen, isSaving, isLoading, hasChanges, close, save  } = props.data;

    return (
        <Dialog
            isOpen={isOpen}
            close={close}
            {...props.dialogProps}
            dialogTitle={props.title}
            titleActions={<>
                {(isLoading || isSaving) && <LoadingIndicator />}
                {props.titleActions}
                {props.data.comments ? props.data.comments.button : null}
                </>}
            actions={<>
                <Button onClick={() => close()}><FormattedMessage id="common.close" /></Button>
                <Button disabled={isSaving || isLoading || !hasChanges} color="primary" variant="contained" onClick={() => save()}><FormattedMessage id="common.save" /></Button>
            </>}>
                {props.children}                            
        </Dialog>
    );
}
