import React, { useEffect, useMemo } from 'react';
import { Arbiter, useArbiterList } from './useArbiterList';
import { RegistrationsListPage } from '../../toolympus/components/Contests/Registrations';
import { ArbiterReviewPopup } from './ArbiterReviewPopup';
import { PseudoLink2 } from '../../toolympus/components/primitives';
import { AdminAccessLSKey } from '../Arbitrators/RoundScoringForm';
import moment from "moment";

export const ArbiterTimeSlotsDisplay2 = (props: { arbitrator: Arbiter }) => {
  const slots = useMemo(() => {
    const datesWithSelectedSlots = (props.arbitrator.timeslots_preferrence_new || [])
      .map((slot: string) => ({
        date: moment(slot).format("DD.MM"),
        time: moment(slot).format("HH:mm"),
      })).reduce<Record<string, string[]>>((r,slot) => {
        if(r[slot.date]) {
          r[slot.date].push(slot.time);
        } else {
          r[slot.date] = [slot.time];
        }
        return r;
      }, {});
    Object.values(datesWithSelectedSlots).forEach(ss => { ss.sort(); });
    return Object.entries(datesWithSelectedSlots).sort((a,b) => a < b ? -1 : 1);
  }, [props.arbitrator]);

  
  return <>
      {slots.map(([d,slots]) => <p key={d}>{d}: {slots.map(s => s).join(", ")}</p>)}
  </>
}

export const ArbiterListPage = () => {
    const data = useArbiterList();

    useEffect(() => {
      if(data.data.length) {
        localStorage.setItem(AdminAccessLSKey, "t");
      }
    }, [data.data])

    return (<>
            <RegistrationsListPage
                data={data}
                fields={{
                    defaultFields: [
                        "email",
                        "fullname",
                        "exclude",
                        "country_new",
                        "timezone",
                        "phone",
                        "work_place",
                        "work_position",
                        "affiliation_universities",
                        "affiliation_teams",
                        "moot_experience",
                        "other_comments",
                        "will_attend_conference",
                        "personal_info_consent",
                        "created_datetime",
                    ],
                    extraSettings: {
                        created_datetime: { utcToLocal: true, },
                        fullname: { cellStyle: { whiteSpace: "nowrap" }},
                        timeslots_preferrence_new: { cellStyle: { whiteSpace: "nowrap" }},
                    },
                    schema: data.schema,
                    storageKey: "_vis_arbs_fields",
                }}
                title="Arbitrators"
                itemDescription={a => `${a.fullname} (${a.email})`}
                fieldElement={f => {
                    switch(f) {
                        case "fullname":
                            return (row,_,orig) => <PseudoLink2 border onClick={() => data.review.open(row._id)}>{orig}</PseudoLink2>
                        case "timeslots_preferrence_new":
                            return r => <ArbiterTimeSlotsDisplay2 arbitrator={r} />;
                    }
                }}
                review={data.review}
                />
            <ArbiterReviewPopup
              settings={data.settings}
              data={data.review}
              remove={data.remove as (t: Arbiter) => Promise<Arbiter>}
              />
        </>);
}
