import React from 'react';
import { GridStageForm } from './GridStageForm';
import { useGridStage } from './useGridStage';

interface Props {
    apiPath: string;
    stageCode: string;
    isActive: boolean;
}

export const GridStageContainer = ({ apiPath, stageCode, isActive }: Props) => {
    const data = useGridStage(apiPath, stageCode, !isActive);
    return <GridStageForm data={data} />;
}
