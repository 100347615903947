import React, { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { FileCopyOutlined, Save } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useItemWithControls } from '../../../api/useWithControls';
import { PowerEditorBase } from '../../PowerDoc';
import { usePowerEditorSettings } from '../../PowerDoc/PowerEditorSettingsContext';
import { DeleteButton, Form, FormGrid, LoadingIndicator, OccupyFreeSpace, SlugEdit, TabPanel, TabsHeader, TitleLink, useSaveable, useTabsState, useWindowHotkey } from '../../primitives';
import { PlaceholderField } from '../../schemed';
import { PublishOrPublishedDate } from '../Elements/PublishOrPublishedDate';
import { getRecordLink } from '../util';
import { TranslationsLinks } from './TranslationsLinks';
import { RecordEditData } from './useRecordEdit';
import { SiteLink } from '../Elements/SiteLink';
import { RecordAttributesForm } from './RecordAttributesForm';
import { LocaleSwitch } from '../../LocalizedRecords';
import { CMSRecordContextProvider } from './CMSRecordContext';
import { useTrackHistory } from '../../HistoryFavorites';

interface Props {
    data: RecordEditData;
}

export const RecordForm = (props: Props) => {
    const { isLoading, hasChanges, data, update, save, lang, setLang } = props.data;
    const { data: config, schema, hasLanguages } = props.data.config;

    useSaveable(props.data);
    useTrackHistory(props.data.data.title ? `${config.label || ""} ${props.data.data.title}` : undefined);

    const history = useHistory();
    const { path } = useRouteMatch();

    const { controls } = useItemWithControls(props.data, { schema });

    const tabs = useTabsState([
        ["content", <FormattedMessage id="cms.record.fields.content" />],
        ["attributes", <FormattedMessage id="cms.record.fields.attributes" />],
    ], "content", "tab");

    const cycleLanguage = useCallback(() => {
      const langCodes = hasLanguages && config.languages && config['translations-in-place']
        ? config.languages
        : [];
      if(langCodes.length) {
        const currentIdx = langCodes.indexOf(lang || "all");
        const nextIdx = (currentIdx + 1) % langCodes.length;
        setLang(langCodes[nextIdx]);
      }
    }, [hasLanguages, config, lang, setLang]);

    useWindowHotkey("mod+alt+l", cycleLanguage);

    const recordType = props.data.data.record_type;
    const listLink = path.replace(":record_type", recordType).replace("/:id", "");

    const editorConfig = usePowerEditorSettings();
    const EditorComponent = editorConfig.EditorComponent || PowerEditorBase;

    return (
        <Form title={props.data.config.isLoading ? "" : <TitleLink to={listLink}>{config.label || config.record_type}</TitleLink>}
            formHeaderProps={{ style: { alignItems: "flex-end" }}}
            headerItems={<>
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator />}
                <SiteLink record={props.data.data} />
                {hasChanges && <IconButton size="small" onClick={() => save()}><Save /></IconButton>}
                <IconButton size="small" onClick={() => props.data.copyItem().then(r => history.push(getRecordLink(path, r))) }>
                    <FileCopyOutlined />
                </IconButton>
                <DeleteButton
                    size="small"
                    remove={() => props.data.remove().then(() => history.push(listLink))}
                    title={<FormattedMessage id="cms.form.remove" values={{ record_type: config.label || config.record_type }} />}
                    preventGoBack
                    />
                
                {hasLanguages && config['translations-in-place'] &&
                    <LocaleSwitch
                        availableLocales={(config.languages || []).map(l => ([l, l]))}
                        locale={lang || ""}
                        setLocale={setLang}
                        />}
            </>}>
            
            <FormGrid columns={props.data.translationLinks.hasTranslationLinks ? "8fr 2fr" : "1fr"} noMargin style={{ alignItems: "end" }}>
                {controls([["title"]])}

                {props.data.translationLinks.hasTranslationLinks &&
                    <TranslationsLinks data={props.data.translationLinks} schema={schema} />}
            </FormGrid>

            <FormGrid columns="3fr 1fr 2fr 2fr 2fr" noMargin gap="dense" style={{ alignItems: "end" }}>
                <SlugEdit
                    field="slug"
                    row={data}
                    onChange={(o,c) => props.data.update(c)}
                    schema={schema["slug"]}
                    sourceField="title"
                    />
                {controls([
                    props.data.config.hasLanguages && !config['translations-in-place'] ? ["lang"] : [PlaceholderField],
                    ["created_datetime", { utcToLocal: true, disabled: true }],
                    ["record_datetime", { utcToLocal: true }],
                    ])}
                <PublishOrPublishedDate
                    field="published_datetime"
                    schema={schema["published_datetime"]}
                    row={data}
                    publish={() => props.data.publish()}
                    unpublish={() => props.data.unpublish()}
                    />
                    
            </FormGrid>


            {props.data.config.hasAttributes && <TabsHeader state={tabs} />}

            <TabPanel state={tabs.forTab("content")}>
                <CMSRecordContextProvider lang={data.lang}>
                    <EditorComponent
                        key={`${data._id}_${lang}`}
                        content={data.content}
                        update={content => update({ content })}
                        />
                </CMSRecordContextProvider>
            </TabPanel>

            <TabPanel state={tabs.forTab("attributes")}>
                <RecordAttributesForm data={props.data} />
            </TabPanel>
        </Form>
    );
}
