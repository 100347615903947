import React, { ReactNode } from 'react';
import { IconButton } from '@mui/material';
import { Add, DeleteOutlined, EditOutlined, FileCopyOutlined, LinkOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { TableForFields, TableRowButtons } from '../schemed';
import { TagEditPopup, useEditTagData, useNewTagData } from './TagEditPopup';
import { TagManagement } from './useTagManagement';
import { DeleteButton } from '../primitives/DeleteButton';
import { TagMerger, TagMergerStatusProps, useTagMerger } from './TagMerger';
import { SearchField } from '../primitives/SearchField';
import { TagUsagePopup } from './TagUsagePopup';
import { PseudoLink } from '../primitives/Common.styles';
import { TagDisplay } from './TagDisplay';

interface Props {
    data: TagManagement;
    mergerProps?: TagMergerStatusProps;
    extraActions?: ReactNode;
}

export const TagManagementForm = (props: Props) => {
    const {
        tags,
        filter,
        setFilter,
        schema,
        isLoading,
        create,
        remove,
        update,
        duplicate,
        merge,
        tagUsage,
    } = props.data;

    const newTag = useNewTagData(create);
    const editTag = useEditTagData(update);
    const duplicateTag = useEditTagData(duplicate);
    const tagMerger = useTagMerger(merge);

    return (
        <>
            <ActionRow firstItemNoMargin>
                <IconButton onClick={() => newTag.open()} size="small">
                    <Add />
                </IconButton>
                <TagMerger data={tagMerger} {...(props.mergerProps || {})} />

                <OccupyFreeSpace />

                {isLoading && <LoadingIndicator />}
                {props.extraActions}
                <SearchField
                    doSearch={() => {}}
                    filter={filter}
                    setFilter={setFilter}
                    noButton
                    autoFocus
                    />
            </ActionRow>

            <TableForFields
                data={tags}
                fields={[
                    ["label"],
                    ["count"],
                ]}
                schema={schema}
                rowButtons={row => <TableRowButtons>
                    <IconButton size="small" onClick={() => editTag.open(row)}>
                        <EditOutlined />
                    </IconButton>
                    <IconButton size="small" onClick={() => duplicateTag.open(row)}>
                        <FileCopyOutlined />
                    </IconButton>
                    <IconButton size="small" onClick={() => tagMerger.addToMerge(row)}>
                        <LinkOutlined />
                    </IconButton>
                    <DeleteButton
                        remove={() => remove(row)}
                        preventGoBack
                        title={<FormattedMessage id="tags.remove" />}
                        hint={(row.count || 0) > 0 ? <FormattedMessage id="tags.remove_in_use" /> : null}
                        button={<IconButton size="small"><DeleteOutlined /></IconButton>}
                    />
                </TableRowButtons>}
                onRowClick={row => {
                    if(tagMerger.other) {
                        tagMerger.addToMerge(row);
                    }
                }}
                fieldElement={f => {
                  switch(f) {
                    case "label":
                      return (r,s,orig) => r.color ? <TagDisplay tag={r} wrap style={{ minWidth: "unset" }} /> : <>{orig}</>;
                    case "count":
                      return r => <PseudoLink onClick={r.count ? () => tagUsage.openFor(r) : undefined}>{r.count}</PseudoLink>
                  }
                }}
                />

            <TagEditPopup
                key="create"
                data={newTag}
                schema={schema}
                title={<FormattedMessage id="tags.create" />}
                saveLabel={<FormattedMessage id="common.add" />}
                />
            <TagEditPopup
                key="update"
                data={editTag}
                schema={schema}
                title={<FormattedMessage id="tags.edit" />}
                saveLabel={<FormattedMessage id="common.save" />}
                />
            <TagEditPopup
                key="duplicate"
                data={duplicateTag}
                schema={schema}
                title={<FormattedMessage id="tags.duplicate" />}
                saveLabel={<FormattedMessage id="common.add" />}
                />
            <TagUsagePopup data={tagUsage} />
        </>
    );
}
