import { useIntl } from "react-intl";
import { apiFetch, FetchTypes } from "../../../api/core";
import { LoadedData, useLoadedData } from "../../../hooks/useLoadedData";
import { FieldType, Schema } from "../../../hooks/useSchema";
import { createTsv } from "../../primitives/tsv";
import { PlayerStanding } from "./types";

export interface GridStageResults extends LoadedData<PlayerStanding[]> {
    schema: Schema;
    updatePass: (party_id: string, pass: boolean) => void;
    getTsv: () => string;
}

export const PlayerStandingSchema = {
    sort_order: { type: FieldType.number, label_id: "contests.rounds.player_standing.sort_order" },
    display_name: { type: FieldType.text, label_id: "contests.rounds.player_standing.display_name" },
    score: { type: FieldType.number, label_id: "contests.rounds.player_standing.score" },
    is_round_winner: { type: FieldType.bool, label_id: "contests.rounds.player_standing.is_round_winner" },
    wins: { type: FieldType.number, label_id: "contests.rounds.player_standing.wins" },
    losses: { type: FieldType.number, label_id: "contests.rounds.player_standing.losses" },
    pass: { type: FieldType.bool, label_id: "contests.rounds.player_standing.pass" },
};

export const useGridStageResults = (apiPath: string, stage_code: string, filter: string, doLoad: boolean): GridStageResults => {
    const data = useLoadedData<PlayerStanding[]>(`${apiPath}/stage/${stage_code}/result`, [], doLoad);
    const { formatMessage } = useIntl();

    const updatePass = (party_id: string, pass: boolean) => {
        apiFetch(`${apiPath}/stage/${stage_code}/result/${party_id}`, FetchTypes.PUT, { pass })
            .then(() => data.reload());
    }

    const filteredData = filter && filter.length ? data.data.filter(r => r.display_name.toLowerCase().includes(filter.toLowerCase())) : data.data;

    const getTsv = () => createTsv(
        [
            formatMessage({ id: PlayerStandingSchema.sort_order.label_id }),
            formatMessage({ id: PlayerStandingSchema.display_name.label_id }),
            formatMessage({ id: PlayerStandingSchema.score.label_id }),
            formatMessage({ id: PlayerStandingSchema.is_round_winner.label_id }),
            formatMessage({ id: PlayerStandingSchema.wins.label_id }),
            formatMessage({ id: PlayerStandingSchema.losses.label_id }),
            formatMessage({ id: PlayerStandingSchema.pass.label_id }),
        ],
        r => ([
            r.sort_order+1,
            r.display_name,
            (r.score || 0).toFixed(2),
            r.is_round_winner,
            r.wins,
            r.losses,
            formatMessage({ id:  r.pass ? "contests.rounds.player_standing.pass_yes" : "contests.rounds.player_standing.pass_no"})
        ]),
        filteredData);

    return {
        ...data,
        data: filteredData,
        schema: PlayerStandingSchema,
        updatePass,
        getTsv,
    }
}