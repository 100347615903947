import React, { PropsWithChildren } from 'react';
import { MediaLibForm, MediaLibProvider } from '../../toolympus/components/medialib';

export const CmsMediaLibProvider = (props: PropsWithChildren<{}>) => {
    return (
        <MediaLibProvider spaceId="2" apiPath="/api/medialib" getFilepath={mf => `/cms-media/${mf.filename}`}>
            {props.children}
        </MediaLibProvider>
    )
}

export const CmsMediaLibPage = () => {
    return (
        <CmsMediaLibProvider>
            <MediaLibForm />
        </CmsMediaLibProvider>
    );
}
